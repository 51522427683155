import { Application } from '@/domain/application';
import { User } from '@/domain/user';
import {
  ApplicationByWardRegionReportDTO,
  ApplicationByWardReportDTO,
} from '@/domain/reports';
import { Action, Data, HttpTypes } from '@/types';
import { ApplicationByUserReport } from '@/domain/applications-by-user-report';

export const getApplicationByWardRegionReportSuccess = (
  state: Data<ApplicationByWardRegionReportDTO>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) Object.assign(ServerData, payload);
  return { ...state, ServerData, ApplicationByWardRegion: payload };
};

export const getUserSuccess = (
  state: Data<{ users: User[] }>,
  action: Action<User[]>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, { users: payload });
  return { ...state, previous };
};

export const getApplicationByUserReportSuccess = (
  state: Data<{ applicationByUserReport: ApplicationByUserReport[] }>,
  action: Action<ApplicationByUserReport[]>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, { applicationByUserReport: payload });
  return { ...state, previous };
};

export const getApplicationByWardRegionReportFinancialYearReportSuccess = (
  state: Data<ApplicationByWardRegionReportDTO>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) Object.assign(ServerData, payload);
  return {
    ...state,
    ServerData,
    ApplicationByWardRegionFinancialYear: payload,
  };
};

export const getApplicationByWardFinancialYearReportSuccess = (
  state: Data<ApplicationByWardReportDTO>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) Object.assign(ServerData, payload);
  return {
    ...state,
    ServerData,
    ApplicationByWardFinancialYear: payload,
  };
};

export const getApplicationByWardReportSuccess = (
  state: Data<ApplicationByWardReportDTO>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const {} = state;
  return { ...state, ApplicationByWard: payload };
};

export const getApplicationByStatusbyFinancialYearFullReportRequestSuccess = (
  state: Data<{ applicationByStatusYearPDF: User[] }>,
  action: Action<User[]>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, {
    applicationByStatusYearPDF: payload,
  });
  return { ...state, previous };
};

export const getApplicationsByReferenceReportSuccess = (
  state: Data<{ applicationByStatusYearPDF: User[] }>,
  action: Action<User[]>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, {
    applicationByReferenceNumberPDF: payload,
  });
  return { ...state, previous };
};
export const getCustomerSuccess = (state: Data<any>, action: Action<any>) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, {
    customerList: payload,
  });
  return { ...state, previous };
};
export const getApplicationAgeByStatusReportSuccess = (
  state: Data<{ applicationAgeByStatusReport: any[] }>,
  action: Action<any[]>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, {
    applicationAgeByStatusReport: payload,
  });
  return { ...state, previous };
};


export const getIndigentBelowAmountSuccess = (
  state: Data<any>,
  action: Action<any>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record as any, {
    indigentBelowAmount: payload,
  });
  return { ...state, previous };
};
