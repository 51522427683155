import { fetchConsilors } from '@/pages/masterData/wardConsilor/services/wardConsilor.service';
import { CustomerAssetProfile } from '@/domain/customer-asset-profile';
import { Action, Data, HttpTypes } from '@/types';
import { routerRedux } from 'dva';
import { WardConsilor } from '@/domain/ward-consilor';

export default {
  namespace: 'wardConsilor',
  state: {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<WardConsilor[]>,
  reducers: {
    fetchConsilorsSuccess(
      state: Data<WardConsilor[]>,
      action: Action<Data<WardConsilor>>,
    ) {
      const { payload } = action;
      let previous = state;
      Object.assign(previous.Record, payload);
      console.log(`record; ${JSON.stringify(previous.Record)}`);
      return { ...state, previous };
    },
    fetchConsilorsFailed(
      state: Data<WardConsilor[]>,
      action: Action<Data<WardConsilor>>,
    ) {
      const { payload } = action;
      let { ServerData } = state;
      Object.assign(ServerData, payload);
      return { ...state, ServerData };
    },
  },
  effects: {
    *fetchConsilors({ payload }, { call, put }: { call: any; put: any }) {
      try {
        const data: HttpTypes.RequestResponse<
          Data<WardConsilor[]>
        > = yield call(fetchConsilors);
        yield put({
          type: 'fetchConsilorsSuccess',
          payload: data,
        });
        return data;
      } catch (err: any) {
        console.log(`err: ${JSON.stringify(err)}`);
        if (err?.data?.statusCode) {
          const httpError: HttpTypes.RequestResponse<
            Data<CustomerAssetProfile>
          > = err;
          const {
            data: { statusCode },
          } = httpError;
          if (statusCode === 400) {
            yield put({
              type: 'fetchConsilorsFailed',
              payload: httpError.data,
            });
          } else if (statusCode === 403) {
            yield call({ type: 'auth/loginExpired', payload: httpError });
          } else if (statusCode === 401) {
            yield put({ type: 'auth/loginExpired', payload: httpError });
          } else {
            yield put({ type: 'fetchConsilorsFailed', payload: httpError });
          }
        } else {
          yield put({ type: 'fetchConsilorsFailed', payload: err });
        }
      }
    },
  },
};
