import { MinorHouseholdProfile } from '@/domain/minor-household-profile';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';

export const createMinorhouseholdProfileRecord = (
  payload: MinorHouseholdProfile,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.post<MinorHouseholdProfile>(
        `${api_base}/api/minor-household-profiles`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error creating minor household profile ${err}`);
      reject(err);
    }
  });
};

export const fetchMinorhouseholdProfile = (propertyId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest.get(
        `${api_base}/api/minor-household-profiles/property/${propertyId}`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ${propertyId} ,  ${err}`);
      reject(err);
    }
  });
};

export const updateMinorhousehold = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.put<MinorHouseholdProfile>(
        `${api_base}/api/minor-household-profiles/${payload.id}`,
        {
          data: payload.data,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ,  ${err}`);
      reject(err);
    }
  });
};
