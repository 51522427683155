import { Action, Data, HttpTypes, ServerData } from '@/types';
import { User } from '@/domain/user';
import { OperationOutCome } from '@/domain/enums/operation-outcome';

export const onCreateUserSuccess = (
  state: Data<User>,
  action: Action<User>,
) => {
  const { payload } = action;
  const Record = payload;
  const Outcome = OperationOutCome.SUCCESS;
  return { ...state, Record, Outcome };
};

export const onCreateUserFailure = (
  state: Data<User>,
  action: Action<ServerData>,
) => {
  const { payload } = action;
  const ServerData = { ...payload };
  const Outcome = OperationOutCome.FAILURE;
  return { ...state, ServerData, Outcome };
};

export const reset = (state: Data<User>) => {
  state = {
    Record: undefined,
    FormStatus: undefined,
    ServerData: undefined,
    Outcome: OperationOutCome.UNKNOWN,
  } as Data<User>;
  return { ...state };
};

export const onPostNotify = (state: Data<User>) => {
  const Outcome = OperationOutCome.UNKNOWN;
  return { ...state, Outcome };
};

export const onUpdatedUserActivated = (
  state: Data<User>,
  action: Action<User>,
) => {
  const { payload } = action;
  const Record = payload;
  return { ...state, Record };
};

export const onFetchUserSuccess = (state: Data<User>, action: Action<User>) => {
  const { payload } = action;
  const Record = { ...payload };
  return { ...state, Record };
};

export const onFetchUserFailure = (
  state: Data<User>,
  action: Action<ServerData>,
) => {
  const { payload } = action;
  const ServerData = { ...payload };
  const Record = {};
  const Outcome = OperationOutCome.FAILURE;
  return { ...state, Record, ServerData, Outcome };
};

export const onUpdateUserSuccess = (
  state: Data<User>,
  action: Action<User>,
) => {
  const { payload } = action;
  const Record = payload;
  const Outcome = OperationOutCome.SUCCESS;
  return { ...state, Record, Outcome };
};

export const onUpdateUserFailure = (
  state: Data<User>,
  action: Action<ServerData>,
) => {
  const { payload } = action;
  const ServerData = { ...payload };
  const UpdateStatus = { Success: false, Failure: true };
  const Outcome = OperationOutCome.FAILURE;
  return { ...state, ServerData, UpdateStatus, Outcome };
};
