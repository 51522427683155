import {
  createCustomerRecord,
  updateCustomerRecord,
  fetchCustomer,
  deleteCustomer,
  searchCustomer,
  createApplicationRequestRecord,
  updateApplicationRequestRecord,
  checkApplicationStatusCall,
  getApplicationRequest,
} from '@/pages/customer/services/customer.service';
import { Customer } from '@/domain/customer';
import { Action, Data, HttpTypes, ServerData } from '@/types';
import {
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILURE,
  CUSTOMER_CLEAR,
} from '@/pages/customer/state/actions/actionTypes';
import {
  CUSTOMER_ASSET_BY_CUSTOMER_REQUEST,
  CUSTOMER_ASSET_UPDATE_CUSTOMER,
} from '@/pages/assetProfile/state/actions/actionTypes';
import { Application, ApplicationRequest } from '@/domain/application';
import { handleServerErrors } from '@/pages/common/state/effects';
import customer from '../..';
import {  APPLICATION_REQUEST_GET_SUCCESS} from '@/pages/application/state/actions/actionTypes';


export function* createCustomer(
  { payload }: Action<Data<Customer>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const { idNumber } = payload as Customer;
    const existingCustomer: Customer = yield call(searchCustomer, idNumber);
    if (!existingCustomer) {
      const data: Customer = yield call(createCustomerRecord, payload);
      yield put({
        type: CUSTOMER_SUCCESS,
        payload: data,
      });
      yield put({
        type: CUSTOMER_ASSET_UPDATE_CUSTOMER,
        payload: data,
      });
      return data;
    } else {
      yield put({
        type: CUSTOMER_SUCCESS,
        payload: existingCustomer,
      });
      yield put({
        type: CUSTOMER_ASSET_UPDATE_CUSTOMER,
        payload: existingCustomer,
      });
      return existingCustomer;
    }
  } catch (err: any) {
    yield handleServerErrors<Data<Customer>>(err, put, call, CUSTOMER_FAILURE);
  }
}

export function* searchForCustomer(
  { payload }: Action<Data<string>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const matchedCustomer: Customer = yield call(searchCustomer, payload);
    if (matchedCustomer) {
      yield put({
        type: CUSTOMER_SUCCESS,
        payload: matchedCustomer,
      });
      yield put({
        type: CUSTOMER_ASSET_BY_CUSTOMER_REQUEST,
        payload: matchedCustomer.id,
      });

      return matchedCustomer;
    }
    return null;
  } catch (err: any) {
    yield handleServerErrors<Data<Customer>>(err, put, call, CUSTOMER_FAILURE);
  }
}

export function* updateCustomer(
  { payload }: Action<Data<Customer>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Customer>> = yield call(
      updateCustomerRecord,
      payload,
    );
    yield put({
      type: CUSTOMER_SUCCESS,
      payload: data,
    });
    yield put({
      type: CUSTOMER_ASSET_UPDATE_CUSTOMER,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getCustomer(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    if (Number.isInteger(payload)) {
      yield put({
        type: CUSTOMER_CLEAR,
        payload: {},
      });
      const data: HttpTypes.RequestResponse<Data<Customer>> = yield call(
        fetchCustomer,
        payload,
      );
      yield put({
        type: CUSTOMER_SUCCESS,
        payload: data,
      });
      yield put({
        type: CUSTOMER_ASSET_UPDATE_CUSTOMER,
        payload: data,
      });
      return data;
    } else {
      const error = {} as ServerData;
      error.Errors.error = `Invalid Payload for customer record ${payload} `;
      yield put({
        type: CUSTOMER_FAILURE,
        payload: error,
      });

      console.log(`payload is undefined`);
    }
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* fetchApplications(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    if (Number.isInteger(payload)) {
      yield put({
        type: CUSTOMER_CLEAR,
        payload: {},
      });
      const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
        fetchCustomer,
        payload,
      );
      yield put({
        type: CUSTOMER_SUCCESS,
        payload: data,
      });
      yield put({
        type: CUSTOMER_ASSET_UPDATE_CUSTOMER,
        payload: data,
      });
      return data;
    } else {
      const error = {} as ServerData;
      error.Errors.error = `Invalid Payload for customer record ${payload} `;
      yield put({
        type: CUSTOMER_FAILURE,
        payload: error,
      });

      console.log(`payload is undefined`);
    }
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* removeCustomer(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    if (Number.isInteger(payload)) {
      const data: HttpTypes.RequestResponse<Data<Customer>> = yield call(
        deleteCustomer,
        payload,
      );
      return data;
    } else {
      const error = {} as ServerData;
      error.Errors.error = `Invalid Payload for customer record ${payload} `;
      yield put({
        type: CUSTOMER_FAILURE,
        payload: error,
      });
      console.log(`payload is ${payload}`);
    }
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* createApplicationRequest(
  { payload }: Action<{ name: string, surname: string, id_number: string, cellphone: string, postal_code: string, preferred_language: string, race: string}>,
  { call, put }: { call: any; put: any },
) {
  try {

    console.log(payload);
    const data: ApplicationRequest = yield call(createApplicationRequestRecord, {
      first_names: payload.name,
      surname: payload.surname,
      id_number: payload.id_number,
      cellphone: payload.cellphone,
      postal_code: payload.postal_code,
      preferred_language: payload.preferred_language,
      race: payload.race
    });
    console.log(data);
  } catch (err: any) {
    yield handleServerErrors<Data<Customer>>(err, put, call, CUSTOMER_FAILURE);
  }
}

export function* updateApplicationRequest(
  { payload }: Action<Data<{ application_id: number, id: number, status: string }>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<{ application_id: number, id: number, status: string }>> = yield call(
      updateApplicationRequestRecord,
      payload,
    );

    console.log(data);
    yield put({
      type: CUSTOMER_SUCCESS,
      payload: data,
    });
    yield put({
      type: CUSTOMER_ASSET_UPDATE_CUSTOMER,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* checkApplicationStatus(
  { payload }: Action<Data<{ id_number: string }>>,
  { call, put }: { call: any; put: any },
) {

  console.log(payload);

  try {
    const data: HttpTypes.RequestResponse<Data<{ id_number: string }>> = yield call(
      checkApplicationStatusCall,
      payload,
    );

    console.log(data);

    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* fetchApplicationRequest(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {

  console.log(payload);

  try {
    const data: HttpTypes.RequestResponse<Data<ApplicationRequest>> = yield call(
      getApplicationRequest,
      payload,
    );

    console.log(data);

    yield put({
      type: APPLICATION_REQUEST_GET_SUCCESS,
      payload: data,
    });

    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* handleNetworkErrors(err: any, put: any, call: any) {
  console.log(`err: ${JSON.stringify(err)}`);
  if (err?.data?.statusCode) {
    const httpError: HttpTypes.RequestResponse<Data<Customer>> = err;
    const {
      data: { statusCode },
    } = httpError;
    if (statusCode === 400) {
      yield put({
        type: CUSTOMER_FAILURE,
        payload: httpError.data,
      });
    } else if (statusCode === 403) {
      yield call({ type: 'auth/loginExpired', payload: httpError });
    } else if (statusCode === 401) {
      yield put({ type: 'auth/loginExpired', payload: httpError });
    } else {
      yield put({ type: 'createError', payload: httpError });
    }
  } else {
    yield put({ type: 'createError', payload: err });
  }
}
