import { Action, Data, HttpTypes, ServerData } from '@/types';
import {
  getcustomerByExpenditureGroupDashboardRequest,
  getApplicationsByDateRequest,
  getcustomerByIncomeGroupDashboardRequest,
  getPropertyByWardDashboardRequest,
  getApplicationsByStatusRequest,
  getApplicationsByWardRequest,
} from '@/pages/dashboard/services/dashboard.service';
import { handleNetworkErrors } from '@/pages/assetProfile/state/effects';
import { PropertyByWardConsilor } from '@/domain/property-wardconsilor-dashboard';
import {
  APPLICATIONS_DASHBOARD_BY_DATE_SUCCESS,
  APPLICATIONS_DASHBOARD_BY_STATUS_SUCCESS,
  APPLICATIONS_DASHBOARD_BY_WARD_SUCCESS,
  CUSTOMER_BY_EXPENDITURE_GROUP_SUCCESS,
  CUSTOMER_BY_INCOME_GROUP_SUCCESS,
  DASHBOARD_SUCCESS,
  PROPERTY_BY_WARDCONSILOR_SUCCESS,
} from '@/pages/dashboard/state/actions/actionTypes';
import { CustomerByIncome } from '@/domain/customer-income-group-dashboard';
import { CustomerByExpenditure } from '@/domain/customer-expenditure-group-dashboard';
import { ApplicationsByDate } from '@/domain/applications-by-date';
import { ApplicationsByStatus } from '@/domain/applications-by-status';
import { ApplicationsByWard } from '@/domain/applications-by-ward';

export function* getPropertyByWardDashboard(
  { payload }: Action<Data<PropertyByWardConsilor[]>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: PropertyByWardConsilor = yield call(
      getPropertyByWardDashboardRequest,
      payload,
    );
    yield put({
      type: PROPERTY_BY_WARDCONSILOR_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getcustomerByIncomeGroupDashboard(
  { payload }: Action<Data<CustomerByIncome[]>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: CustomerByIncome = yield call(
      getcustomerByIncomeGroupDashboardRequest,
      payload,
    );
    yield put({
      type: CUSTOMER_BY_INCOME_GROUP_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getcustomerByExpenditureGroupDashboard(
  { payload }: Action<Data<CustomerByExpenditure[]>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: CustomerByExpenditure = yield call(
      getcustomerByExpenditureGroupDashboardRequest,
      payload,
    );
    yield put({
      type: CUSTOMER_BY_EXPENDITURE_GROUP_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getApplicationsByDate(
  { payload }: Action<Data<any>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: ApplicationsByDate[] = yield call(
      getApplicationsByDateRequest,
      payload,
    );
    yield put({
      type: APPLICATIONS_DASHBOARD_BY_DATE_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getApplicationsByStatus(
  { payload }: Action<Data<any>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: ApplicationsByStatus[] = yield call(
      getApplicationsByStatusRequest,
    );
    yield put({
      type: APPLICATIONS_DASHBOARD_BY_STATUS_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getApplicationsByWard(
  { payload }: Action<Data<any>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: ApplicationsByWard[] = yield call(getApplicationsByWardRequest);
    yield put({
      type: APPLICATIONS_DASHBOARD_BY_WARD_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}
