import { OperationOutCome } from '@/domain/enums/operation-outcome';
import { User } from '@/domain/user';
import {
  createUser,
  updateUserActivated,
  fetchUser,
  updateUser,
} from '@/pages/admin/users/state/effects';
import {
  onCreateUserSuccess,
  onCreateUserFailure,
  reset,
  onPostNotify,
  onUpdatedUserActivated,
  onFetchUserSuccess,
  onFetchUserFailure,
  onUpdateUserSuccess,
  onUpdateUserFailure,
} from '@/pages/admin/users/state/reducers';
import { Data } from '@/types';

export default {
  namespace: 'userAdmin',
  state: {
    Record: undefined,
    FormStatus: undefined,
    ServerData: undefined,
    Outcome: OperationOutCome.UNKNOWN,
  } as Data<User>,
  reducers: {
    onCreateUserSuccess,
    onCreateUserFailure,
    onUpdatedUserActivated,
    onFetchUserSuccess,
    onFetchUserFailure,
    reset,
    onUpdateUserSuccess,
    onUpdateUserFailure,
    onPostNotify,
  },
  effects: {
    createUser,
    updateUserActivated,
    fetchUser,
    updateUser,
  },
};
