import { OperationOutCome } from '@/domain/enums/operation-outcome';
import { MunicipalService } from '@/domain/municipal-service';
import { Action, Data, HttpTypes } from '@/types';

export const success = (
  state: Data<MunicipalService>,
  action: Action<Data<MunicipalService>>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record, payload);
  const Outcome = OperationOutCome.SUCCESS;
  return { ...state, previous, Outcome };
};

export const getSuccess = (
  state: Data<MunicipalService>,
  action: Action<Data<MunicipalService>>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record, payload);
  const Outcome = OperationOutCome.UNKNOWN;
  return { ...state, previous, Outcome };
};

export const reset = (state: Data<MunicipalService>) => {
  state = {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
    Outcome: OperationOutCome.UNKNOWN,
  } as Data<MunicipalService>;
  return { ...state };
};

export const clearNotify = (state: Data<MunicipalService>) => {
  const Outcome = OperationOutCome.UNKNOWN;
  return { ...state, Outcome };
};

export const failure = (
  state: Data<MunicipalService>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  const Outcome = OperationOutCome.FAILURE;
  return { ...state, ServerData, Outcome };
};
