import { Property } from '@/domain/property';
import { RequestData } from '@ant-design/pro-table/lib/typing';
import { GrantsHouseholdProfile } from '@/domain/grants-household-profile';
import { api_base } from '@/pages/common/services';
import { httpRequest } from '@/pages/common/interceptors';

export const fetchStandTypes = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.get(`${api_base}/api/stand-types`, {
        headers: headers,
      });
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const fetchWardConsilors = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.get(`${api_base}/api/ward-consilors`, {
        headers: headers,
      });
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const createProperty = (payload: Property) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.post(`${api_base}/api/properties`, {
        data: payload,
      });
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const fetchProperty = (payload: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/properties/${payload}`,
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const fetchByCustomerProperty = (payload: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/properties/customer/${payload}`,
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const updateProperty = (payload: Property) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.put(
        `${api_base}/api/properties/${payload.id}`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const query = (
  customerId: number,
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<Property>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/properties/customer/${customerId}?page=${page}&size=${pageSize}&sort=createdDate`,
        { getResponse: true },
      );
      const result: RequestData<Property> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const putGrantHouseholdProfile = (payload: GrantsHouseholdProfile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { property } = payload;
      const { id } = property;
      const response = await httpRequest.post(
        `${api_base}/api/grants-household-profiles/property/${id}`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error updating grant household profile ${err}`);
      reject(err);
    }
  });
};

export const fetchGrantHousehold = (payload: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/grants-household-profiles/property/${payload}`,
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const fetchMunicipalServices = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await httpRequest.get(`${api_base}/api/municipal-services`);
      resolve(data);
    } catch (err) {
      console.log(`error getting municipal services,  ${err}`);
      reject(err);
    }
  });
};
