import { Data } from '@/types';
import {
  success,
  failure,
  clear,
  onGetApplicationSuccess,
  deleteSuccess,
  onGetApplicationRequestSuccess
} from '@/pages/application/state/reducers';
import {
  fetchApplication,
  createApplication,
  fetchApplications,
  removeApplication,
  updateApplication,
} from '@/pages/application/state/effects';
import { Application } from '@/domain/application';
import { OperationOutCome } from '@/domain/enums/operation-outcome';

export default {
  namespace: 'application',
  state: {
    Record: {},
    FormStatus: {},
    Outcome: OperationOutCome.UNKNOWN,
    ServerData: { Errors: {} },
  } as Data<Application>,
  reducers: {
    success,
    failure,
    clear,
    deleteSuccess,
    onGetApplicationSuccess,
    onGetApplicationRequestSuccess
  },
  effects: {
    fetchApplication,
    createApplication,
    fetchApplications,
    removeApplication,
    updateApplication,
  },
};
