import { Customer } from '@/domain/customer';
import { CustomerAssetProfile } from '@/domain/customer-asset-profile';
import { Action, Data, HttpTypes } from '@/types';

export const createSuccess = (
  state: Data<CustomerAssetProfile>,
  action: Action<Data<CustomerAssetProfile>>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record, payload);
  return { ...state, previous };
};

export const updateCustomer = (
  state: Data<CustomerAssetProfile>,
  action: Action<Customer>,
) => {
  const { payload } = action;
  const customer = payload;
  let { Record } = state;
  if (Record) {
    Record.customer = customer;
  }

  return { ...state, Record };
};

export const createFailure = (
  state: Data<CustomerAssetProfile>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  return { ...state, ServerData };
};

export const clear = (state: Data<CustomerAssetProfile>, action: any) => {
  const customer = state?.Record?.customer;
  const Record = { customer } as CustomerAssetProfile;
  return { ...state, Record };
};
