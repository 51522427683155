import { WardConsilor } from '@/domain/ward-consilor';
import { Action, Data, HttpTypes } from '@/types';

export const createSuccess = (
  state: Data<WardConsilor>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const clear = (
  state: Data<WardConsilor>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  state = {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<WardConsilor>;
  const Record = {} as WardConsilor;
  return { ...state, Record };
};

export const createFailure = (
  state: Data<WardConsilor>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  state.Record = {} as WardConsilor;
  return { ...state, ServerData };
};
