import { Action, Data, HttpTypes, Auth } from '@/types';

export const success = (state: Data<Auth>, action: Action<Auth>) => {
  const Record: Auth = { ...action.payload };
  return { ...state, Record };
};

export const failure = (state: Data<Auth>, action: Action<Auth>) => {
  const Record = { ...action.payload };
  return { ...state, Record };
};
