import request, {
  RequestOptionsInit,
  RequestMethod,
  ResponseError,
} from 'umi-request';

const errorHandler = (error: ResponseError) => {
  if (error.message === 'Failed to fetch') {
    const errorMessage = {
      Errors: {
        statusCode: 500,
        message: 'No internet connection',
        error: error.message,
      },
    };
    throw errorMessage;
  } else {
    throw error;
  }
};

export const authHeaderInterceptor = (
  url: string,
  options: RequestOptionsInit,
) => {
  const uri = new URL(url);
  if (uri.pathname.includes('/api/authenticate'))
    return {
      url: `${url}`,
      options: { ...options, interceptors: true },
    };
  options.errorHandler = errorHandler;
  const token = localStorage.getItem('api_token') || '';
  const authHeader = { Authorization: `Bearer ${token}` };
  return {
    url: `${url}`,
    options: { ...options, interceptors: true, headers: authHeader },
  };
};

request.interceptors.request.use(authHeaderInterceptor);

export const httpRequest: RequestMethod = request;
