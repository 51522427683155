import { AuthStatus } from '@/domain/enums/auth-status';
import { Data, Auth } from '@/types';
import { success, failure } from '../state/reducers';
import { checkToken, login, loginExpired } from '../state/effects';

export default {
  namespace: 'auth',
  state: {
    Record: { authStatus: AuthStatus.UNINITIALIZED },
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<Auth>,

  reducers: {
    success,
    failure,
  },
  effects: {
    login,
    loginExpired,
    checkToken,
  },
};
