import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';

export const fetchConsilors = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.get(`${api_base}/api/ward-consilors`, {
        headers: headers,
      });
      resolve(response);
    } catch (err) {
      console.log(`error fecthing consilors ${err}`);
      reject(err);
    }
  });
};
