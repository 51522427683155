import { Occupant } from '@/domain/occupant';
import { OccupantSkill } from '@/domain/occupant-skill';
import { RequestData } from '@ant-design/pro-table/lib/typing';
import { SocialProfile } from '@/domain/social-profile';
import { MonthlyIncomeProfile } from '@/domain/monthly-income-profile';
import { MonthlyExpenditureProfile } from '@/domain/monthly-expenditure-profile';
import { ResponseError } from 'umi-request';
import { api_base } from '@/pages/common/services';
import { httpRequest } from '@/pages/common/interceptors';

export const postOccupant = (payload: Occupant) => {
  return new Promise<Occupant>(async (resolve, reject) => {
    try {
      const response = await httpRequest.post(`${api_base}/api/occupants`, {
        data: payload,
      });
      resolve(response);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const putOccupant = (payload: Occupant) => {
  return new Promise<Occupant>(async (resolve, reject) => {
    try {
      const { id } = payload;
      let hasErrorred = false;
      let previousError = '';
      httpRequest
        .put(`${api_base}/api/occupants/${id}`, {
          data: payload,
          getResponse: true,

          errorHandler: (error: ResponseError<any>) => {
            console.log(`request error: ${JSON.stringify(error)}`);
            const { data } = error;
            const { message } = data;
            hasErrorred = true;
            previousError = message;
            throw Error(previousError);
          },
        })
        .then((value) => {
          if (hasErrorred) {
            reject(previousError);
            return previousError;
          } else {
            const response = {} as Occupant;
            Object.assign(response, value);
            resolve(response);
            return response;
          }
        })
        .catch((reason) => {
          reject(reason);
          return reason;
        });
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const query = (
  propertyId: number,
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<Occupant>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/occupants/property/${propertyId}?page=${page}&size=${pageSize}&sort=createdDate`,
        { getResponse: true },
      );
      const result: RequestData<Occupant> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const getOccupant = (payload: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/occupants/${payload}`,
      );
      resolve(response);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const getOccupantSocialProfile = (payload: number) => {
  return new Promise<SocialProfile>(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/social-profiles/occupant/${payload}`,
      );
      resolve(response);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const postSocialProfile = (payload: SocialProfile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.post(
        `${api_base}/api/social-profiles`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};
export const putSocialProfile = (payload: SocialProfile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { id } = payload;
      const response = await httpRequest.put(
        `${api_base}/api/social-profiles/${id}`,
        {
          data: payload,
          getResponse: true,
          errorHandler: (error: ResponseError<any>) => {
            console.log(`request error: ${JSON.stringify(error)}`);
            reject;
          },
        },
      );
      resolve(response.data);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const postOccupantSkill = (payload: OccupantSkill) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.post(
        `${api_base}/api/occupant-skills`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const putOccupantSkill = (payload: OccupantSkill) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.put(
        `${api_base}/api/occupant-skills`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const getOccupantSkills = (socialProfileId: number): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/occupant-skills/socialProfile/${socialProfileId}`,
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const postMonthlyIncomeProfile = (
  occupantId: number,
  payload: MonthlyIncomeProfile,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.post(
        `${api_base}/api/monthly-income-profiles/${occupantId}`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const putMonthlyIncomeProfile = (payload: MonthlyIncomeProfile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { id } = payload;
      const response = await httpRequest.put(
        `${api_base}/api/monthly-income-profiles/${id}`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const getMonthlyIncomeProfile = (occupantId: number): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/monthly-income-profiles/${occupantId}`,
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const postMonthlyExpenditureProfile = (
  occupantId: number,
  payload: MonthlyExpenditureProfile,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(payload);
      const response = await httpRequest.post(
        `${api_base}/api/monthly-expenditure-profiles/${occupantId}`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const putMonthlyExpenditureProfile = (
  payload: MonthlyExpenditureProfile,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { id } = payload;
      const response = await httpRequest.put(
        `${api_base}/api/monthly-expenditure-profiles/${id}`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const getMonthlyExpenditureProfile = (
  occupantId: number,
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get(
        `${api_base}/api/monthly-expenditure-profiles/${occupantId}`,
      );
      resolve(response);
    } catch (error) {
      console.log(`error: ${error}`);
      reject(error);
    }
  });
};

export const deleteOccupantRequest = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.delete<Occupant>(
        `${api_base}/api/occupants/${payload}`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ,  ${err}`);
      reject(err);
    }
  });
};

export const putOccupantGrant = (payload: Occupant) => {
  return new Promise<Occupant>(async (resolve, reject) => {
    try {
      const { id } = payload;
      let hasErrorred = false;
      let previousError = '';
      httpRequest
        .put(`${api_base}/api/occupants/${id}`, {
          data: payload,
          getResponse: true,
          errorHandler: (error: ResponseError<any>) => {
            console.log(`request error: ${JSON.stringify(error)}`);
            const { data } = error;
            const { message } = data;
            hasErrorred = true;
            previousError = message;
            throw Error(previousError);
          },
        })
        .then((value) => {
          if (hasErrorred) {
            reject(previousError);
            return previousError;
          } else {
            const response = {} as Occupant;
            Object.assign(response, value);
            resolve(response);
            return response;
          }
        })
        .catch((reason) => {
          reject(reason);
          return reason;
        });
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};
