import { MinorHouseholdProfile } from '@/domain/minor-household-profile';
import { Data } from '@/types';
import { createSuccess, createFailure, clear } from '../state/reducers';
import {
  createMinorHouseholdProfile,
  getMinorHouseholdProfileByProperty,
  updateMinorhouseholdProfile,
} from '../state/effects';

export default {
  namespace: 'minorHousehold',
  state: {
    Record: {},
    ServerData: { Errors: {} },
  } as Data<MinorHouseholdProfile>,
  reducers: {
    createSuccess,
    createFailure,
    clear,
  },
  effects: {
    createMinorHouseholdProfile,
    getMinorHouseholdProfileByProperty,
    updateMinorhouseholdProfile,
  },
};
