const namespace = 'application';
export const APPLICATION_SUCCESS = `${namespace}/success`;
export const APPLICATION_CLEAR = `${namespace}/clear`;
export const APPLICATION_FAILURE = `${namespace}/failure`;
export const APPLICATION_CREATE_REQUEST = `${namespace}/createApplication`;
export const APPLICATION_UPDATE_REQUEST = `${namespace}/updateApplication`;
export const APPLICATION_GETALL_REQUEST = `${namespace}/fetchApplications`;
export const APPLICATION_GET_REQUEST = `${namespace}/fetchApplication`;
export const APPLICATION_DELETE_REQUEST = `${namespace}/removeApplication`;
export const APPLICATION_GET_SUCCESS = `${namespace}/onGetApplicationSuccess`;
export const APPLICATION_DELETE_SUCCESS = `${namespace}/deleteSuccess`;
export const APPLICATION_REQUEST_GET_SUCCESS = `${namespace}/onGetApplicationRequestSuccess`;
