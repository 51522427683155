import { MinorHouseholdProfile } from '@/domain/minor-household-profile';
import { Action, Data, HttpTypes } from '@/types';

export const createSuccess = (
  state: Data<MinorHouseholdProfile>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const clear = (
  state: Data<MinorHouseholdProfile>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const Record = {} as MinorHouseholdProfile;
  return { ...state, Record };
};

export const createFailure = (
  state: Data<MinorHouseholdProfile>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  state.Record = {} as MinorHouseholdProfile;
  return { ...state, ServerData };
};
