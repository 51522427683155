import { ApplicationDocument } from '@/domain/application-document';
import { ApplicationDocumentCheck } from '@/domain/application-document-check';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';

export const postApplicationDocument = (applicationId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const applicationDocument = {
        name: `application-${applicationId}`,
        description: `application-${applicationId}`,
        application: { id: applicationId },
      } as ApplicationDocument;
      const data = await httpRequest.post<ApplicationDocument>(
        `${api_base}/api/application-documents`,
        {
          data: applicationDocument,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const postApplicationDocumentCheck = (
  applicationDocumentCheck: ApplicationDocumentCheck,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.post<ApplicationDocumentCheck>(
        `${api_base}/api/application-document-checks`,
        {
          data: applicationDocumentCheck,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};

export const getApplicationDocument = (
  applicationId: number,
): Promise<ApplicationDocument[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const result = await httpRequest.get(
        `${api_base}/api/application-documents/application/${applicationId}`,
        {
          headers: headers,
        },
      );
      resolve(result);
    } catch (err) {
      console.log(`error: ${err}`);
      reject(err);
    }
  });
};
