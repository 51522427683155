import { httpRequest } from '@/pages/common/interceptors';
import { RequestData } from '@ant-design/pro-table';
import { TotalApplications } from '@/pages/analytics/state/effects';
const api_base = process.env.UMI_APP_API_BASE;

export const getTotalApplicationsRequest = (
  payload: any,
): Promise<TotalApplications> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/applications/analytics/totalApplications`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as any);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getTotalApprovalRateRequest = (
  payload: any,
): Promise<TotalApplications> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/applications/analytics/totalApproval`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as any);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getTotalHouseholdRequest = (
  payload: any,
): Promise<TotalApplications> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/applications/analytics/totalHousehold`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as any);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationsByRegionRequest = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/applications/analytics/byRegion?page=${page}&size=${pageSize}&sort=createdDate`,
        { getResponse: true },
      );
      const result: RequestData<any> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};
