import { MunicipalService } from '@/domain/municipal-service';
import { Action, Data } from '@/types';
import { handleServerErrors } from '@/pages/common/state/effects';
import {
  MUNICIPAL_SERVICE_FAILURE,
  MUNICIPAL_SERVICE_GET_SUCCESS,
  MUNICIPAL_SERVICE_SUCCESS,
} from '@/pages/municipalService/state/action/actionTypes';
import {
  createMunicipalService,
  deleteMunicipalService,
  getMunicipalService,
  updateMunicipalService,
} from '@/pages/municipalService/services/municipalService.service';

export function* create(
  { payload }: Action<Data<MunicipalService>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: MunicipalService = yield call(createMunicipalService, payload);
    yield put({
      type: MUNICIPAL_SERVICE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<MunicipalService>>(
      err,
      put,
      call,
      MUNICIPAL_SERVICE_FAILURE,
    );
  }
}

export function* get({ payload }: Action<Data<number>>, { call, put }: any) {
  try {
    const data: MunicipalService = yield call(getMunicipalService, payload);
    yield put({
      type: MUNICIPAL_SERVICE_GET_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<MunicipalService>>(
      err,
      put,
      call,
      MUNICIPAL_SERVICE_FAILURE,
    );
  }
}

export function* update(
  { payload }: Action<Data<MunicipalService>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: MunicipalService = yield call(updateMunicipalService, payload);
    yield put({
      type: MUNICIPAL_SERVICE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<MunicipalService>>(
      err,
      put,
      call,
      MUNICIPAL_SERVICE_FAILURE,
    );
  }
}

export function* deleteMunicipalServiceRequest(
  { payload }: Action<Data<MunicipalService>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: MunicipalService = yield call(deleteMunicipalService, payload);
    yield put({
      type: MUNICIPAL_SERVICE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<MunicipalService>>(
      err,
      put,
      call,
      MUNICIPAL_SERVICE_FAILURE,
    );
  }
}
