import { Data, ReportsData } from '@/types';
import { OperationOutCome } from '@/domain/enums/operation-outcome';
import {
  getApplicationByUserReportSuccess,
  getUserSuccess,
  getApplicationByWardFinancialYearReportSuccess,
  getApplicationByWardRegionReportSuccess,
  getApplicationByWardReportSuccess,
  getApplicationByStatusbyFinancialYearFullReportRequestSuccess,
  getApplicationsByReferenceReportSuccess,
  getCustomerSuccess,
  getApplicationAgeByStatusReportSuccess,
  getIndigentBelowAmountSuccess,
} from '@/pages/reports/state/reducers';
import {
  getAllUsers,
  getApplicationByUserFullReportRequest,
  getApplicationByWardRegionReport,
  getApplicationByWardRegionReportFinancialYearReport,
  getApplicationByWardReport,
  getApplicationByWardByFinancialYear,
  getApplicationByStatusbyFinancialYearFullReportRequest,
  getApplicationsByReferenceReport,
  getAllCustomers,
  getApplicationAgeByStatusReport,
  getIndigentBelowAmountDropdown,
} from '@/pages/reports/state/effects';

export default {
  namespace: 'reports',
  state: {
    Record: {},
    FormStatus: {},
    Outcome: OperationOutCome.UNKNOWN,
    ServerData: { Errors: {} },
  } as ReportsData<any, any, any, any, any, any>,
  reducers: {
    getApplicationByUserReportSuccess,
    getUserSuccess,
    getApplicationByStatusbyFinancialYearFullReportRequestSuccess,
    getApplicationsByReferenceReportSuccess,
    getApplicationByWardFinancialYearReportSuccess,
    getApplicationByWardRegionReportSuccess,
    getApplicationByWardReportSuccess,
    getCustomerSuccess,
    getApplicationAgeByStatusReportSuccess,
    getIndigentBelowAmountSuccess,
  },
  effects: {
    getAllUsers,
    getApplicationByUserFullReportRequest,
    getApplicationByWardByFinancialYear,
    getApplicationByWardReport,
    getApplicationByWardRegionReport,
    getApplicationByWardRegionReportFinancialYearReport,
    getApplicationByStatusbyFinancialYearFullReportRequest,
    getApplicationsByReferenceReport,
    getAllCustomers,
    getApplicationAgeByStatusReport,
    getIndigentBelowAmountDropdown,
  },
};
