import { CustomerByIncome } from '@/domain/customer-income-group-dashboard';
import { PropertyByWardConsilor } from '@/domain/property-wardconsilor-dashboard';
import { AdditionalDataThird, DashboardData, Data } from '@/types';
import {
  getcustomerByExpenditureGroupDashboard,
  getApplicationsByDate,
  getcustomerByIncomeGroupDashboard,
  getPropertyByWardDashboard,
  getApplicationsByStatus,
  getApplicationsByWard,
} from '@/pages/dashboard/state/effects';
import {
  success,
  failure,
  clear,
  getPropertyByWardConsilorsSuccess,
  getcustomerByIncomeGroupDashboardSuccess,
  getcustomerByExpenditureGroupDashboardSuccess,
  getApplicationsByDateSuccess,
  getApplicationsByStatusSuccess,
  getApplicationsByWardSuccess,
} from '@/pages/dashboard/state/reducers';
import { CustomerByExpenditure } from '@/domain/customer-expenditure-group-dashboard';
import { ApplicationsByDate } from '@/domain/applications-by-date';
import { ApplicationsByStatus } from '@/domain/applications-by-status';
import { ApplicationsByWard } from '@/domain/applications-by-ward';

export default {
  namespace: 'dashboard',
  state: {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  reducers: {
    success,
    failure,
    clear,
    getPropertyByWardConsilorsSuccess,
    getcustomerByIncomeGroupDashboardSuccess,
    getcustomerByExpenditureGroupDashboardSuccess,
    getApplicationsByDateSuccess,
    getApplicationsByStatusSuccess,
    getApplicationsByWardSuccess,
  },
  effects: {
    getPropertyByWardDashboard,
    getcustomerByIncomeGroupDashboard,
    getcustomerByExpenditureGroupDashboard,
    getApplicationsByDate,
    getApplicationsByStatus,
    getApplicationsByWard,
  },
};
