const namespace = 'userAdmin';

export const ADMIN_USER_RESET = `${namespace}/reset`;
export const ADMIN_USER_POSTNOTIFY = `${namespace}/onPostNotify`;
export const ADMIN_USER_ADD = `${namespace}/createUser`;
export const ADMIN_USER_ADD_SUCCESS = `${namespace}/onCreateUserSuccess`;
export const ADMIN_USER_ADD_FAILURE = `${namespace}/onCreateUserFailure`;
export const ADMIN_USER_UPDATE_ACTIVE = `${namespace}/updateUserActivated`;
export const ADMIN_USER_UPDATE_ACTIVE_SUCCESS = `${namespace}/onUpdatedUserActivated`;
export const ADMIN_USER_FETCH = `${namespace}/fetchUser`;
export const ADMIN_USER_FETCH_SUCCESS = `${namespace}/onFetchUserSuccess`;
export const ADMIN_USER_FETCH_FAILURE = `${namespace}/onFetchUserFailure`;
export const ADMIN_USER_UPDATE = `${namespace}/updateUser`;
export const ADMIN_USER_UPDATE_SUCCESS = `${namespace}/onUpdateUserSuccess`;
export const ADMIN_USER_UPDATE_FAILURE = `${namespace}/onUpdateUserFailure`;
