const namespace = 'analytics';
export const APPLICATION_SUCCESS = `${namespace}/success`;
export const APPLICATION_CLEAR = `${namespace}/clear`;
export const APPLICATION_FAILURE = `${namespace}/failure`;

export const ANALYTICS_GET_TOTAL_APPLICATIONS_REQUEST = `${namespace}/getTotalApplications`;
export const ANALYTICS_GET_TOTAL_APPLICATIONS_SUCCESS = `${namespace}/getTotalApplicationSuccess`;

export const ANALYTICS_GET_TOTAL_APPROVAL_RATE_REQUEST = `${namespace}/getTotalApprovalRate`;
export const ANALYTICS_GET_TOTAL_APPROVAL_RATE_SUCCESS = `${namespace}/getTotalApprovalRateSuccess`;

export const ANALYTICS_GET_TOTAL_HOUSEHOLD_REQUEST = `${namespace}/getTotalHousehold`;
export const ANALYTICS_GET_TOTAL_HOUSEHOLD_SUCCESS = `${namespace}/getTotalHouseholdSuccess`;

export const ANALYTICS_GET_TOTAL_BY_REGION_REQUEST = `${namespace}/getTotalByRegion`;
export const ANALYTICS_GET_TOTAL_BY_REGION_SUCCESSS = `${namespace}/getTotalByRegionSuccess`;
