import { ApplicationDocument } from '@/domain/application-document';
import { OperationOutCome } from '@/domain/enums/operation-outcome';
import { Action, Data, HttpTypes } from '@/types';

export const success = (
  state: Data<ApplicationDocument[]>,
  action: Action<ApplicationDocument[]>,
) => {
  const { payload } = action;
  const { Record } = state;
  Record!.push(...payload);
  return { ...state, Record };
};

export const clear = (
  state: Data<ApplicationDocument[]>,
  action: Action<HttpTypes.Response>,
) => {
  const { Record } = state;
  Record!.splice(0);
  return { ...state, Record };
};

export const failure = (
  state: Data<ApplicationDocument[]>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) Object.assign(ServerData, payload);
  const Outcome = OperationOutCome.FAILURE;
  return { ...state, ServerData, Outcome };
};
