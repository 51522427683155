import { Application } from '@/domain/application';
import { Action, Data, HttpTypes } from '@/types';
import {
  APPLICATION_GET_SUCCESS,
  APPLICATION_FAILURE,
  APPLICATION_CLEAR,
  APPLICATION_SUCCESS,
  APPLICATION_DELETE_SUCCESS,
} from '@/pages/application/state/actions/actionTypes';
import {
  deleteApplication,
  getApplication,
  postApplication,
  putApplication,
} from '@/pages/application/services/application.service';
import { ApplicationUpdate } from '@/domain/application-update';
import { handleServerErrors } from '@/pages/common/state/effects';

export function* createApplication(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: Data<Application> = yield call(postApplication, payload);
    yield put({ type: APPLICATION_SUCCESS, payload: data });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<Application>>(
      err,
      put,
      call,
      APPLICATION_FAILURE,
    );
    return null;
  }
}

export function* updateApplication(
  { payload }: Action<ApplicationUpdate>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
      putApplication,
      payload,
    );
    yield put({ type: APPLICATION_SUCCESS, payload: data });
  } catch (err: any) {
    yield handleServerErrors<Data<Application>>(
      err,
      put,
      call,
      APPLICATION_FAILURE,
    );
  }
}

export function* fetchApplications(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    yield put({
      type: APPLICATION_CLEAR,
      payload: {},
    });
    const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
      getApplication,
      payload,
    );
    yield put({
      type: APPLICATION_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleServerErrors<Data<Application>>(
      err,
      put,
      call,
      APPLICATION_FAILURE,
    );
  }
}

export function* fetchApplication(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
      getApplication,
      payload,
    );
    yield put({ type: APPLICATION_GET_SUCCESS, payload: data });
  } catch (err) {
    yield handleServerErrors<Data<Application>>(
      err,
      put,
      call,
      APPLICATION_FAILURE,
    );
  }
}

export function* removeApplication(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
      deleteApplication,
      payload,
    );
    yield put({
      type: APPLICATION_DELETE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<Application>>(
      err,
      put,
      call,
      APPLICATION_FAILURE,
    );
  }
}
