import { Application, ApplicationRequest } from '@/domain/application';
import { OperationOutCome } from '@/domain/enums/operation-outcome';
import { Action, Data, HttpTypes } from '@/types';

export const success = (
  state: Data<Application>,
  action: Action<HttpTypes.Response>,
) => {
  const { payload } = action;
  const { Record }: any =
    state || ({ Record: {} as Application } as Data<Application>);
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const clear = (
  state: Data<Application>,
  action: Action<HttpTypes.Response>,
) => {
  const Record = {
    Outcome: OperationOutCome.UNKNOWN,
  } as Data<Application>;
  return { Record };
};

export const failure = (
  state: Data<Application>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) Object.assign(ServerData, payload);
  state.Record = {} as Application;
  const Outcome = OperationOutCome.FAILURE;
  return { ...state, ServerData, Outcome };
};

export const onGetApplicationSuccess = (
  state: Data<Application>,
  action: Action<Application>,
) => {
  const { payload } = action;
  const {} = state;
  return { ...state, ...payload };
};

export const deleteSuccess = (
  state: Data<Application>,
  action: Action<HttpTypes.Response>,
) => {
  const { payload } = action;
  const { Record }: any =
    state || ({ Record: {} as Application } as Data<Application>);
  Object.assign(Record, payload);
  const Outcome = OperationOutCome.SUCCESS;
  return { ...state, Record, Outcome };
};



export const onGetApplicationRequestSuccess = (
  state: Data<ApplicationRequest>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};
