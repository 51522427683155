import { Application, ApplicationRequest } from '@/domain/application';
import { ApplicationUpdate } from '@/domain/application-update';
import { OperationOutCome } from '@/domain/enums/operation-outcome';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';
import { Data, HttpTypes, ServerData } from '@/types';
import { RequestData } from '@ant-design/pro-table/lib/typing';

export const queryApplications = async (
  params: {
    pageSize: number | undefined;
    page: number | undefined;
    customer: string;
    idNumber: string;
    createdDate: string[];
    status: string;
  },
  sort: any,
  filter: any,
): Promise<RequestData<Application>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const {
        pageSize,
        page,
        customer,
        idNumber,
        createdDate,
        status,
      } = params;

      let endpointFilters = '';
      if (customer) {
        endpointFilters = `${endpointFilters}&customer=${customer}`;
      }
      if (idNumber) {
        endpointFilters = `${endpointFilters}&idNumber=${idNumber}`;
      }
      if (status) {
        endpointFilters = `${endpointFilters}&status=${status}`;
      }

      if (createdDate && createdDate.length > 1) {
        const startDate = createdDate[0];
        const endDate = createdDate[1];
        endpointFilters = `${endpointFilters}&startDate=${startDate}&endDate=${endDate}`;
      }

      const response = await httpRequest.get(
        `${api_base}/api/applications?page=${page}&size=${pageSize}&sort=createdDate${endpointFilters}`,
      );
      const { data, total } = response;
      const result: RequestData<Application> = {
        data: data,
        success: response ? true : false,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying applications endpoint ${err}`);
      reject(err);
    }
  });
};

export const getApplication = async (
  applicationId: number,
): Promise<Data<Application>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const data = await httpRequest.get<Application>(
        `${api_base}/api/applications/${applicationId}`,
      );
      const response = {
        Outcome: OperationOutCome.SUCCESS,
        Record: data,
      } as Data<Application>;
      resolve(response);
    } catch (err) {
      console.log(`error calling get application endpoint ${err}`);
      const response = {
        Outcome: OperationOutCome.FAILURE,
        ServerData: {
          data: {
            error: err,
          } as HttpTypes.ErrorResponse,
        } as ServerData,
      } as Data<Application>;
      reject(response);
    }
  });
};

export const putApplication = async (update: ApplicationUpdate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { applicationId, notes, status } = update;
      const payload = {
        status,
        notes,
      };
      const data = await httpRequest.put<Application>(
        `${api_base}/api/applications/${applicationId}`,
        {
          data: payload,
        },
      );
      const response = {
        Outcome: OperationOutCome.SUCCESS,
        Record: data,
      } as Data<Application>;
      resolve(response);
    } catch (err) {
      console.log(`error calling put application endpoint ${err}`);
      const response = {
        Outcome: OperationOutCome.FAILURE,
        ServerData: {
          data: {
            error: err,
          } as HttpTypes.ErrorResponse,
        } as ServerData,
      } as Data<Application>;
      reject(response);
    }
  });
};

export const postApplication = (customerId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpRequest.post<Application>(
        `${api_base}/api/applications/customer/${customerId}`,
        {
          getResponse: true,
        },
      );
      if (result.response.ok) {
        const response = {
          Outcome: OperationOutCome.SUCCESS,
          Record: result.data,
        } as Data<Application>;
        resolve(response);
      } else {
        const response = {
          Outcome: OperationOutCome.FAILURE,
          ServerData: {
            data: {
              statusCode: result.response.status,
              message: result.response.statusText,
            },
          },
        } as Data<Application>;
        reject(response);
      }
    } catch (err) {
      const response = {
        Outcome: OperationOutCome.FAILURE,
        ServerData: {
          data: {
            error: err,
          } as HttpTypes.ErrorResponse,
        } as ServerData,
      } as Data<Application>;
      reject(response);
    }
  });
};

export const deleteApplication = (applicationId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.delete<Application>(
        `${api_base}/api/applications/${applicationId}`,
        {
          data: applicationId,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ,  ${err}`);
      reject(err);
    }
  });
};

export const fetchApplicationRequests = async (
  params: {
    pageSize: number | undefined;
    page: number | undefined;
    names: string;
    id_number: string;
    created_date: string[];
  },
  sort: any,
  filter: any,
): Promise<RequestData<ApplicationRequest>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const { pageSize, page, names, id_number, created_date } = params;

      console.log(created_date);

      let endpointFilters = '';
      if (names) {
        endpointFilters = `${endpointFilters}&names=${names}`;
      }
      if (id_number) {
        endpointFilters = `${endpointFilters}&id_number=${id_number}`;
      }

      if (created_date) {
        endpointFilters = `${endpointFilters}&start_date=${created_date[0]}&end_date=${created_date[1]}`;
      }
      const response = await httpRequest.get(
        `${api_base}/api/application-requests?page=${page}&size=${pageSize}&sort=createdDate${endpointFilters}`,
      );
      const { data, total } = response;
      const result: RequestData<ApplicationRequest> = {
        data: data,
        success: response ? true : false,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying applications endpoint ${err}`);
      reject(err);
    }
  });
};
