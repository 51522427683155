import { User } from '@/domain/user';
import { api_base } from '@/pages/common/services';
import request from 'umi-request';

export async function loginAPI(payload: any) {
  try {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const response = await request.post(`${api_base}/api/authenticate`, {
      data: payload,
      headers: headers,
    });
    return response;
  } catch (err) {
    return err;
  }
}

export async function getUserInfo(): Promise<User | any> {
  try {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const response = await request.get(`${api_base}/api/account`, {
      headers: headers,
    });
    return response;
  } catch (err) {
    return err;
  }
}
