import { Application } from '@/domain/application';
import { Action, Data, HttpTypes } from '@/types';
import { TotalApplications } from '@/pages/analytics/state/effects';

export const success = (
  state: Data<any>,
  action: Action<HttpTypes.Response>,
) => {
  const { payload } = action;
  const { Record }: any =
    state || ({ Record: {} as Application } as Data<Application>);
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const clear = (state: Data<any>, action: Action<HttpTypes.Response>) => {
  const Record = {} as Data<any>;
  return { Record };
};

export const failure = (
  state: Data<any>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) Object.assign(ServerData, payload);
  state.Record = {};
  return { ...state, ServerData };
};

export const getTotalApplicationSuccess = (
  state: Data<TotalApplications>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  return { ...state, totalApplications: payload };
};

export const getTotalApprovalRateSuccess = (
  state: Data<TotalApplications>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  return { ...state, totalApprovalRate: payload };
};

export const getTotalHouseholdSuccess = (
  state: Data<TotalApplications>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  return { ...state, totalHousehold: payload };
};
