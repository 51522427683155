// @ts-nocheck

  import DashboardTwoTone from '@ant-design/icons/es/icons/DashboardTwoTone';
import FundTwoTone from '@ant-design/icons/es/icons/FundTwoTone';
import AppstoreTwoTone from '@ant-design/icons/es/icons/AppstoreTwoTone';
import FolderTwoTone from '@ant-design/icons/es/icons/FolderTwoTone';
import CopyTwoTone from '@ant-design/icons/es/icons/CopyTwoTone';
import LockTwoTone from '@ant-design/icons/es/icons/LockTwoTone';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined'
  export default {
    DashboardTwoTone,
FundTwoTone,
AppstoreTwoTone,
FolderTwoTone,
CopyTwoTone,
LockTwoTone,
UserOutlined
  }