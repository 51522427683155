const namespace = 'municipalService';
export const MUNICIPAL_SERVICE_CREATE_REQUEST = `${namespace}/create`;
export const MUNICIPAL_SERVICE_GET_REQUEST = `${namespace}/get`;
export const MUNICIPAL_SERVICE_UPDATE_REQUEST = `${namespace}/update`;
export const MUNICIPAL_SERVICE_DELETE_REQUEST = `${namespace}/deleteMunicipalServiceRequest`;

export const MUNICIPAL_SERVICE_SUCCESS = `${namespace}/success`;
export const MUNICIPAL_SERVICE_GET_SUCCESS = `${namespace}/getSuccess`;
export const MUNICIPAL_SERVICE_FAILURE = `${namespace}/failure`;
export const MUNICIPAL_SERVICE_CLEAR_NOTIFY = `${namespace}/clearNotify`;
export const MUNICIPAL_SERVICE_RESET = `${namespace}/reset`;
