import { ApplicationsByDate } from '@/domain/applications-by-date';
import { ApplicationsByStatus } from '@/domain/applications-by-status';
import { ApplicationsByWard } from '@/domain/applications-by-ward';
import { Customer } from '@/domain/customer';
import { CustomerByExpenditure } from '@/domain/customer-expenditure-group-dashboard';
import { CustomerByIncome } from '@/domain/customer-income-group-dashboard';
import { PropertyByWardConsilor } from '@/domain/property-wardconsilor-dashboard';
import { Action, DashboardData, Data, HttpTypes } from '@/types';

export const success = (
  state: Data<any>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const clear = (
  state: Data<any>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const Record = {} as any;
  return { ...state, Record };
};

export const failure = (
  state: Data<any>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  state.Record = {} as any;
  return { ...state, ServerData };
};

export const getPropertyByWardConsilorsSuccess = (
  state: DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  action: Action<PropertyByWardConsilor[]>,
) => {
  const { payload } = action;
  const propertyWardConsilor = { Record: payload };
  return { ...state, propertyWardConsilor };
};

export const getcustomerByIncomeGroupDashboardSuccess = (
  state: DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  action: Action<CustomerByIncome[]>,
) => {
  const { payload } = action;
  const customerByMonthlyIncome = { Record: payload };
  return { ...state, customerByMonthlyIncome };
};

export const getcustomerByExpenditureGroupDashboardSuccess = (
  state: DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  action: Action<CustomerByIncome[]>,
) => {
  const { payload } = action;
  const customerByMonthlyExpenditure = { Record: payload };
  return { ...state, customerByMonthlyExpenditure };
};

export const getApplicationsByDateSuccess = (
  state: DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  action: Action<ApplicationsByDate[]>,
) => {
  const { payload } = action;
  const applicationsByDate = { Record: payload };
  return { ...state, applicationsByDate };
};

export const getApplicationsByStatusSuccess = (
  state: DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  action: Action<ApplicationsByDate[]>,
) => {
  const { payload } = action;
  const applicationsByStatus = { Record: payload };
  return { ...state, applicationsByStatus };
};

export const getApplicationsByWardSuccess = (
  state: DashboardData<
    PropertyByWardConsilor[],
    CustomerByIncome[],
    CustomerByExpenditure[],
    ApplicationsByDate[],
    ApplicationsByStatus[],
    ApplicationsByWard[]
  >,
  action: Action<ApplicationsByWard[]>,
) => {
  const { payload } = action;
  const applicationsByWard = { Record: payload };
  return { ...state, applicationsByWard };
};
