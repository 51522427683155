const namespace = 'customer';
export const CUSTOMER_CREATE_REQUEST = `${namespace}/createCustomer`;
export const CUSTOMER_UPDATE_REQUEST = `${namespace}/updateCustomer`;
export const CUSTOMER_SUCCESS = `${namespace}/success`;
export const CUSTOMER_CLEAR = `${namespace}/clear`;
export const CUSTOMER_FAILURE = `${namespace}/failure`;
export const CUSTOMER_GET_REQUEST = `${namespace}/getCustomer`;
export const CUSTOMER_DELETE_REQUEST = `${namespace}/removeCustomer`;
export const CUSTOMER_SEARCH_REQUEST = `${namespace}/searchForCustomer`;
export const SELFHEP_CREATE_APPLICATION_REQUEST = `${namespace}/createApplicationRequest`;
export const SELFHEP_UPDATE_APPLICATION_REQUEST = `${namespace}/updateApplicationRequest`;
export const SELFHEP_CHECK_APPLICATION_STATUS = `${namespace}/checkApplicationStatus`;
export const SELFHEP_GET_APPLICATION_REQUEST = `${namespace}/fetchApplicationRequest`;

