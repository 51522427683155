const namespace = 'dashboard';
export const PROPERTY_BY_WARDCONSILOR = `${namespace}/getPropertyByWardDashboard`;
export const PROPERTY_BY_WARDCONSILOR_SUCCESS = `${namespace}/getPropertyByWardConsilorsSuccess`;

export const CUSTOMER_BY_INCOME_GROUP_SUCCESS = `${namespace}/getcustomerByIncomeGroupDashboardSuccess`;
export const CUSTOMER_BY_INCOME_GROUP = `${namespace}/getcustomerByIncomeGroupDashboard`;

export const CUSTOMER_BY_EXPENDITURE_GROUP_SUCCESS = `${namespace}/getcustomerByExpenditureGroupDashboardSuccess`;
export const CUSTOMER_BY_EXPENDITURE_GROUP = `${namespace}/getcustomerByExpenditureGroupDashboard`;

export const APPLICATIONS_DASHBOARD_BY_DATE = `${namespace}/getApplicationsByDate`;
export const APPLICATIONS_DASHBOARD_BY_DATE_SUCCESS = `${namespace}/getApplicationsByDateSuccess`;

export const APPLICATIONS_DASHBOARD_BY_STATUS = `${namespace}/getApplicationsByStatus`;
export const APPLICATIONS_DASHBOARD_BY_STATUS_SUCCESS = `${namespace}/getApplicationsByStatusSuccess`;

export const APPLICATIONS_DASHBOARD_BY_WARD = `${namespace}/getApplicationsByWard`;
export const APPLICATIONS_DASHBOARD_BY_WARD_SUCCESS = `${namespace}/getApplicationsByWardSuccess`;

export const DASHBOARD_SUCCESS = `${namespace}/success`;
