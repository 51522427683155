import { Action, Data, HttpTypes } from '@/types';
import {
  APPLICATION_DOCUMENT_SUCCESS,
  APPLICATION_DOCUMENT_FAILURE,
} from '@/pages/applicationDocument/state/actions/actionTypes';
import {
  postApplicationDocument,
  postApplicationDocumentCheck,
  getApplicationDocument,
} from '@/pages/applicationDocument/services/applicationDocument.service';
import { handleServerErrors } from '@/pages/common/state/effects';
import { ApplicationDocumentCheck } from '@/domain/application-document-check';
import { ApplicationDocument } from '@/domain/application-document';

export function* createApplicationDocument(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<ApplicationDocument>
    > = yield call(postApplicationDocument, payload);
    yield put({
      type: APPLICATION_DOCUMENT_SUCCESS,
      payload: [data],
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<ApplicationDocument>>(
      err,
      put,
      call,
      APPLICATION_DOCUMENT_FAILURE,
    );
  }
}

export function* createApplicationDocumentCheck(
  { payload }: Action<ApplicationDocumentCheck>,
  { call, put, fork }: { call: any; put: any; fork: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<ApplicationDocumentCheck>
    > = yield call(postApplicationDocumentCheck, payload);
  } catch (err: any) {
    yield handleServerErrors<Data<ApplicationDocumentCheck>>(
      err,
      put,
      call,
      APPLICATION_DOCUMENT_FAILURE,
    );
  }
}

export function* fetchApplicationDocument(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: ApplicationDocumentCheck[] = yield call(
      getApplicationDocument,
      payload,
    );

    yield put({
      type: APPLICATION_DOCUMENT_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<ApplicationDocumentCheck[]>>(
      err,
      put,
      call,
      APPLICATION_DOCUMENT_FAILURE,
    );
  }
}
