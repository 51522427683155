import { Action, Data, HttpTypes } from '@/types';
import {
  getUser,
  postUser,
  putUser,
  putUserActivated,
} from '@/pages/admin/users/services/users.service';
import {
  ADMIN_USER_ADD_SUCCESS,
  ADMIN_USER_ADD_FAILURE,
  ADMIN_USER_FETCH_SUCCESS,
  ADMIN_USER_FETCH_FAILURE,
  ADMIN_USER_UPDATE_SUCCESS,
  ADMIN_USER_UPDATE_FAILURE,
} from '@/pages/admin/users/state/actions/actionTypes';
import { User } from '@/domain/user';

export function* createUser(
  { payload }: Action<User>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<User>> = yield call(
      postUser,
      payload,
    );
    yield put({
      type: ADMIN_USER_ADD_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error: any) {
    yield put({ type: ADMIN_USER_ADD_FAILURE, payload: error });
  }
}

export function* updateUserActivated(
  { payload }: Action<{ userId: number; active: boolean }>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<User> = yield call(
      putUserActivated,
      payload,
    );
  } catch (error: any) {
    yield put({ type: ADMIN_USER_ADD_FAILURE, payload: error });
  }
}

export function* fetchUser(
  { payload }: Action<string>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<User> = yield call(getUser, payload);
    yield put({ type: ADMIN_USER_FETCH_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: ADMIN_USER_FETCH_FAILURE, payload: err });
  }
}

export function* updateUser(
  { payload }: Action<User>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<User>> = yield call(
      putUser,
      payload,
    );
    yield put({
      type: ADMIN_USER_UPDATE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error: any) {
    yield put({ type: ADMIN_USER_UPDATE_FAILURE, payload: error });
  }
}
