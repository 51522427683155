import { User } from '@/domain/user';
import { Avatar, Dropdown, Menu, Spin } from 'antd';
import { LogoutOutlined, DisconnectOutlined } from '@ant-design/icons';
import { InitialState } from './types';
import { UserRole } from './domain/enums/user-roles';
import './pages/index.css';
function getUserInfo() {
  const auth_token = localStorage.getItem('api_token') || '';
  if (auth_token == null || auth_token == '') {
    const loginUrl = location.origin + '/login';
    if (location.href !== loginUrl && !location.href.includes('selfhelp')) {
      window.location.replace(loginUrl);
    }
    return null;
  }
  const userInfoJSON = localStorage.getItem('userinfo') || '';
  const user = {} as User;
  Object.assign(user, JSON.parse(userInfoJSON));
  return user;
}

export function getInitialState() {
  return {
    name: getUserInfo() ? getUserInfo()?.firstName : '',
    roles: getUserInfo()?.authorities.map((value) => {
      const role: UserRole | undefined = UserRole[value];
      return role;
    }),
    avatar: '',
  } as InitialState;
}

const logout = () => {
  localStorage.removeItem('api_token');
  localStorage.removeItem('userinfo');
  const loginUrl = location.origin + '/login';
  window.location.replace(loginUrl);
};

const handleOfflineMode = () => {

  const apiToken:  Record<string, string> = { 'q': localStorage.getItem('api_token') as string };
  const queryString = new URLSearchParams(apiToken).toString();

  window.open(`${process.env.UMI_APP_OFFLINE_APP_URL}/?${queryString}`, '_blank');
}

const menu = (initialState: any) => {
  return (
    <Menu className="umi-plugin-layout-menu">
      <Menu.Item key="logout" onClick={logout}>
        <LogoutOutlined />
        &nbsp; Sign out
      </Menu.Item>
      <Menu.Item key="offline" onClick={handleOfflineMode}>
        <DisconnectOutlined />
        &nbsp; Offline mode
      </Menu.Item>
    </Menu>
  );
};

const avatar = (initialState: any) => {
  return (
    <span className="umi-plugin-layout-action">
      <Avatar
        size="small"
        className="umi-plugin-layout-avatar"
        src={initialState?.avatar || ''}
        alt="avatar"
      />
      <span className="umi-plugin-layout-name">{initialState?.name}</span>
    </span>
  );
};

export const layout = {
  rightRender: (initialState: any) => {
    return (
      <Dropdown
        overlay={menu(initialState)}
        overlayClassName="umi-plugin-layout-container"
      >
        {avatar(initialState)}
      </Dropdown>
    );
  },
  onError: (error: Error, info: any) => {
    //todo: ideally send to sentry and log stash
    console.log(`error: ${error}`);
    console.log(`info: ${info}`);
  },
};
