import { Action, AdditionalDataFourth, Data, HttpTypes } from '@/types';
import { Property } from '@/domain/property';
import { WardConsilor } from '@/domain/ward-consilor';
import { StandType } from '@/domain/stand-type';
import { GrantsHouseholdProfile } from '@/domain/grants-household-profile';
import { MunicipalService } from '@/domain/municipal-service';

export const createSuccess = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<
    AdditionalDataFourth<
      Property,
      WardConsilor[],
      StandType[],
      GrantsHouseholdProfile,
      MunicipalService[]
    >
  >,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record, payload);
  return { ...state, previous };
};

export const createFailure = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  return { ...state, ServerData };
};

export const createError = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  return { ...state, serverError: payload };
};

export const getWardConsilorsSuccess = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<WardConsilor[]>,
) => {
  const { payload } = action;
  const PrimaryStash = { Record: payload } as Data<WardConsilor[]>;
  return { ...state, PrimaryStash };
};

export const getStandTypesSuccess = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<StandType[]>,
) => {
  const { payload } = action;
  const SecondaryStash = { Record: payload } as Data<StandType[]>;
  return { ...state, SecondaryStash };
};

export const getPropertySuccess = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<Property>,
) => {
  const { payload } = action;
  const Record = { ...payload };
  return { ...state, Record };
};

export const clearErrors = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<
    AdditionalDataFourth<
      Property,
      WardConsilor[],
      StandType[],
      GrantsHouseholdProfile,
      MunicipalService[]
    >
  >,
) => {
  const { ServerData } = state;
  Object.assign(ServerData?.Errors, {});
  return { ...state, ServerData };
};

export const updateGrantHouseholdProfileSucccess = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<GrantsHouseholdProfile>,
) => {
  const { payload } = action;
  const ThirdStash = { Record: payload } as Data<GrantsHouseholdProfile>;
  return { ...state, ThirdStash };
};

export const reset = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
) => {
  state = {} as AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >;
  return { ...state };
};

export const getMunicipalServicesSuccess = (
  state: AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    GrantsHouseholdProfile,
    MunicipalService[]
  >,
  action: Action<MunicipalService[]>,
) => {
  const { payload } = action;
  const FourthStash = { Record: payload } as Data<MunicipalService[]>;
  return { ...state, FourthStash };
};
