import { Data } from '@/types';
import { createSuccess, createFailure, clear } from '../state/reducers';
import {
  createWardConsilor,
  deleteWardConsilor,
  getWardConsilor,
  updateWardConsilor,
} from '@/pages/wardConsilor/state/effects';
import { WardConsilor } from '@/domain/ward-consilor';

export default {
  namespace: 'wardconsilor',
  state: {
    Record: {},
    ServerData: { Errors: {} },
  } as Data<WardConsilor>,
  reducers: {
    createSuccess,
    createFailure,
    clear,
  },
  effects: {
    createWardConsilor,
    getWardConsilor,
    updateWardConsilor,
    deleteWardConsilor,
  },
};
