import { Error } from '@/components/common/notifications';
import { APPLICATION_FAILURE } from '@/pages/application/state/actions/actionTypes';
import { HttpTypes } from '@/types';

export function* handleServerErrors<T>(
  err: any,
  put: any,
  call: any,
  errorAction: string,
) {
  console.log(`err: ${JSON.stringify(err)}`);
  if (err?.data?.statusCode) {
    const httpError: HttpTypes.RequestResponse<T> = err;
    const {
      data: { statusCode },
    } = httpError;
    if (statusCode === 400) {
      yield put({
        type: errorAction,
        payload: httpError.data,
      });
    } else if (statusCode === 403) {
      yield call({ type: 'auth/loginExpired', payload: httpError });
    } else if (statusCode === 401) {
      yield put({ type: 'auth/loginExpired', payload: httpError });
    } else {
      yield put({ type: errorAction, payload: httpError.data });
    }
  } else {
    if (err?.ServerData && errorAction === APPLICATION_FAILURE) {
      Error(err?.ServerData?.data?.error?.data);
    }
    console.log(err);
    yield put({ type: errorAction, payload: err });
  }
}
