const namespace = 'reports';

export const APPLICATION_REPORT_BY_WARDSTATUS_SUCCESS = `${namespace}/getApplicationByWardRegionReportSuccess`;
export const APPLICATION_REPORT_BY_WARDSTATUS_REQUEST = `${namespace}/getApplicationByWardRegionReport`;
export const APPLICATION_REPORT_BY_WARDSTATUS_FAILURE = `${namespace}/failure`;

export const APPLICATION_USERS_REQUEST = `${namespace}/getAllUsers`;
export const APPLICATION_USERS_SUCCESS = `${namespace}/getUserSuccess`;

export const APPLICATION_REPORT_BY_USER = `${namespace}/getApplicationByUserFullReportRequest`;
export const APPLICATION_REPORT_BY_USER_SUCCESS = `${namespace}/getApplicationByUserReportSuccess`;
export const APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_SUCCESS = `${namespace}/getApplicationByWardStatusFinancialYearReportSuccess`;
export const APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_REQUEST = `${namespace}/getApplicationByWardRegionReportFinancialYearReport`;
export const APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_FAILURE = `${namespace}/failure`;

export const APPLICATION_GET_REPORT_SUCCESS = `${namespace}/getApplicationByWardReportSuccess`;
export const APPLICATION_GET_REPORT_REQUEST = `${namespace}/getApplicationByWardReport`;
export const APPLICATION_GET_REPORT_FAILURE = `${namespace}/failure`;

export const APPLICATION_GET_BY_FINANCIAL_YEAR_REPORT_SUCCESS = `${namespace}/getApplicationByWardFinancialYearReportSuccess`;
export const APPLICATION_GET_BY_FINANCIAL_YEAR_REPORT_REQUEST = `${namespace}/getApplicationByWardByFinancialYear`;
export const APPLICATION_GET_BY_FINANCIAL_YEAR_REPORT_FAILURE = `${namespace}/failure`;

export const APPLICATION_STATUS_GET_BY_FINANCIAL_YEAR_REPORT = `${namespace}/getApplicationByStatusbyFinancialYearFullReportRequest`;
export const APPLICATION_STATUS_GET_BY_FINANCIAL_YEAR_REPORT_SUCCESS = `${namespace}/getApplicationByStatusbyFinancialYearFullReportRequestSuccess`;

export const APPLICATION_STATUS_GET_APPLICATION_BY_REFERENCE_NUMBER = `${namespace}/getApplicationsByReferenceReport`;
export const APPLICATION_STATUS_GET_APPLICATION_BY_REFERENCE_NUMBER_SUCCESS = `${namespace}/getApplicationsByReferenceReportSuccess`;

export const FETCH_REPORTS_CUSTOMERS_SUCCESS = `${namespace}/getCustomerSuccess`;
export const FETCH_REPORTS_CUSTOMERS_FAIL = `${namespace}/fecthCustomerSuccess`;
export const FETCH_REPORTS_CUSTOMERS = `${namespace}/getAllCustomers`;

export const APPLICATION_STATUS_GET_APPLICATION_AGE_BY_STATUS = `${namespace}/getApplicationAgeByStatusReport`;
export const APPLICATION_STATUS_GET_APPLICATION_AGE_BY_STATUS_SUCCESS = `${namespace}/getApplicationAgeByStatusReportSuccess`;
export const FETCH_REPORTS_INDIGENT_BELOW_AMOUNT_SUCCESS = `${namespace}/getIndigentBelowAmountSuccess`;
export const FETCH_REPORTS_INDIGENT_BELOW_AMOUNT_FAIL = `${namespace}/getIndigentBelowAmountFailure`;
export const FETCH_REPORTS_INDIGENT_BELOW_AMOUNT = `${namespace}/getIndigentBelowAmountDropdown`;
