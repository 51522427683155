import {
  CreateCustomerAssetProfile,
  GetByCustomerId,
  UpdateCustomerAssetProfile,
} from '@/pages/assetProfile/services/assetProfile.service';
import { Customer } from '@/domain/customer';
import { Action, Data, HttpTypes } from '@/types';
import {
  CUSTOMER_ASSET_CREATE_SUCCESS,
  CUSTOMER_ASSET_FAILURE,
  CUSTOMER_ASSET_RESET,
} from '../actions/actionTypes';
import { CustomerAssetProfile } from '@/domain/customer-asset-profile';

export function* handleNetworkErrors(err: any, put: any, call: any) {
  console.log(`err: ${JSON.stringify(err)}`);
  if (err?.data?.statusCode) {
    const httpError: HttpTypes.RequestResponse<Data<Customer>> = err;
    const {
      data: { statusCode },
    } = httpError;
    if (statusCode === 400) {
      yield put({
        type: CUSTOMER_ASSET_FAILURE,
        payload: httpError.data,
      });
    } else if (statusCode === 403) {
      yield call({ type: 'auth/loginExpired', payload: httpError });
    } else if (statusCode === 401) {
      yield put({ type: 'auth/loginExpired', payload: httpError });
    } else {
      yield put({ type: 'createError', payload: httpError });
    }
  } else {
    yield put({ type: 'createError', payload: err });
  }
}

export function* create(
  { payload }: Action<Data<CustomerAssetProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<CustomerAssetProfile>
    > = yield call(CreateCustomerAssetProfile, payload);
    yield put({
      type: CUSTOMER_ASSET_CREATE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getByCustomer(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<CustomerAssetProfile>
    > = yield call(GetByCustomerId, payload);
    yield put({
      type: CUSTOMER_ASSET_RESET,
      payload: {},
    });
    yield put({
      type: CUSTOMER_ASSET_CREATE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    console.log(`err: ${JSON.stringify(err)}`);
    if (err?.data?.statusCode) {
      const httpError: HttpTypes.RequestResponse<
        Data<CustomerAssetProfile>
      > = err;
      const {
        data: { statusCode },
      } = httpError;
      if (statusCode === 400) {
        yield put({
          type: CUSTOMER_ASSET_FAILURE,
          payload: httpError.data,
        });
      } else if (statusCode === 403) {
        yield call({ type: 'auth/loginExpired', payload: httpError });
      } else if (statusCode === 401) {
        yield put({ type: 'auth/loginExpired', payload: httpError });
      } else {
        yield put({ type: 'createError', payload: httpError });
      }
    } else {
      yield put({ type: 'createError', payload: err });
    }
  }
}

export function* updateCustomerProfile(
  { payload }: Action<Data<CustomerAssetProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<CustomerAssetProfile>
    > = yield call(UpdateCustomerAssetProfile, payload);
    yield put({
      type: CUSTOMER_ASSET_CREATE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    console.log(`err: ${JSON.stringify(err)}`);
    yield handleNetworkErrors(err, put, call);
  }
}
