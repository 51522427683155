import { AdultHouseholdProfile } from '@/domain/adult-household-profile';
import { Action, Data } from '@/types';
import {
  ADULT_HOUSEHOLD_PROFILE_SUCCESS,
  ADULT_HOUSEHOLD_PROFILE_FAILURE,
} from '@/pages/adultHousehold/state/actions/actionTypes';
import {
  createAdultHouseholdProfile,
  getAdultHouseholdProfile,
  updateAdultHouseholdProfile,
} from '@/pages/adultHousehold/services/adultHousehold.service';
import { handleServerErrors } from '@/pages/common/state/effects';

export function* create(
  { payload }: Action<Data<AdultHouseholdProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: AdultHouseholdProfile = yield call(
      createAdultHouseholdProfile,
      payload,
    );
    yield put({
      type: ADULT_HOUSEHOLD_PROFILE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<AdultHouseholdProfile>>(
      err,
      put,
      call,
      ADULT_HOUSEHOLD_PROFILE_FAILURE,
    );
  }
}

export function* get({ payload }: Action<Data<number>>, { call, put }: any) {
  try {
    const data: AdultHouseholdProfile = yield call(
      getAdultHouseholdProfile,
      payload,
    );
    yield put({
      type: ADULT_HOUSEHOLD_PROFILE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<AdultHouseholdProfile>>(
      err,
      put,
      call,
      ADULT_HOUSEHOLD_PROFILE_FAILURE,
    );
  }
}

export function* update(
  { payload }: Action<Data<AdultHouseholdProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: AdultHouseholdProfile = yield call(
      updateAdultHouseholdProfile,
      payload,
    );
    yield put({
      type: ADULT_HOUSEHOLD_PROFILE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<AdultHouseholdProfile>>(
      err,
      put,
      call,
      ADULT_HOUSEHOLD_PROFILE_FAILURE,
    );
  }
}
