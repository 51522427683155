const namespace = 'property';
export const PROPERTY_CREATE_REQUEST = `${namespace}/create`;
export const PROPERTY_WARDCONSILOR_REQUEST = `${namespace}/getWardConsilors`;
export const PROPERTY_STANDTYPES_REQUEST = `${namespace}/getStandTypes`;

export const PROPERTY_CREATE_SUCCESS = `${namespace}/createSuccess`;
export const PROPERTY_CREATE_FAILURE = `${namespace}/createFailure`;
export const PROPERTY_WARDCONSILOR_SUCCESS = `${namespace}/getWardConsilorsSuccess`;
export const PROPERTY_STANDTYPES_SUCCESS = `${namespace}/getStandTypesSuccess`;
export const PROPERTY_CLEAR_ERRORS = `${namespace}/clearErrors`;
export const PROPERTY_FETCH_REQUEST = `${namespace}/getProperty`;
export const PROPERTY_FETCH_BY_CUSTOMER_REQUEST = `${namespace}/getByCustomerProperty`;
export const PROPERTY_FETCH_REQUEST_SUCCESS = `${namespace}/getPropertySuccess`;
export const PROPERTY_UPDATE_REQUEST = `${namespace}/update`;

export const GRANDHOUSEHOLD_PUT_REQUEST = `${namespace}/updateGrantHouseholdProfile`;
export const GRANDHOUSEHOLD_PUT_SUCCESS = `${namespace}/updateGrantHouseholdProfileSucccess`;
export const GRANDHOUSEHOLD_GET_REQUEST = `${namespace}/getGranthouseholdProfile`;

export const PROPERTY_MUNICIPAL_SERVICES_SUCCESS = `${namespace}/getMunicipalServicesSuccess`;
export const PROPERTY_MUNICIPAL_SERVICES_REQUEST = `${namespace}/getMunicipalServices`;

export const PROPERTY_RESET = `${namespace}/reset`;
