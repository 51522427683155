import { Customer } from '@/domain/customer';
import { RequestData } from '@ant-design/pro-table/lib/typing';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';
import { Success, Error } from '@/components/common/notifications';
import { ErrorInfo } from 'react';
import { ApplicationRequest } from '@/domain/application';
import { Data, HttpTypes, ServerData } from '@/types';

export const createCustomerRecord = (payload: Customer) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { firstNames } = payload;
      const initials = getInitials(firstNames);
      const dto = { ...payload, initials: initials, postalAddressLine1: ' ' };

      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.post(`${api_base}/api/customers`, {
        data: dto,
        headers: headers,
      });
      if (response.message) {
        Error(response.message);
        resolve(response);
        return;
      }
      Success('Customer personal information was updated');
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const updateCustomerRecord = (payload: Customer) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { id } = payload;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.put(
        `${api_base}/api/customers/${id}`,
        {
          data: payload,
          headers: headers,
        },
      );
      if (response.message) {
        Error(response.message);
        resolve(response);
        return;
      }
      Success('Customer personal information was updated');
      resolve(response);
    } catch (err: any) {
      console.log(`error creating customer ${err}`);
      Error(err.message);
      reject(err);
    }
  });
};

export const fetchCustomer = (customerId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/customers/${customerId}`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error getting customer: ${customerId} ,  ${err}`);
      reject(err);
    }
  });
};

export const deleteCustomer = (customerId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest.delete(
        `${api_base}/api/customers/${customerId}`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error deleting customer: ${customerId} ,  ${err}`);
      reject(err);
    }
  });
};

export const query = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<Customer>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/customers?page=${page}&size=${pageSize}&sort=createdDate`,
        { getResponse: true },
      );
      const result: RequestData<Customer> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying customers endpoint ${err}`);
      reject(err);
    }
  });
};

export const searchCustomer = (idNumber: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await httpRequest.get(
        `${api_base}/api/customers/search/${idNumber}`,
      );
      resolve(data);
    } catch (err: any) {
      if (err?.data) {
        const { statusCode } = err.data;
        if (statusCode === 404) {
          resolve(null);
          return;
        }
      }
      reject(err);
    }
  });
};

function getInitials(firstNames: string) {
  return String(firstNames.split(' ').map((name: string) => name[0])).replace(
    ',',
    '',
  );
}

export const createApplicationRequestRecord = (payload: { first_names: string, surname: string, id_number: number, cellphone: string, postal_code: string, preferred_language: string, race: string}) => {
  return new Promise(async (resolve, reject) => {
    try {

      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.post(`${api_base}/api/application-requests`, {
        data: { ...payload },
        headers: headers,
      });
      console.log(response);
      if (response.message) {
        Error(response.message);
        resolve(response);
        return;
      }
      Success('Application requested successfully');
      resolve(response);
    } catch (err: any) {
      Error(`${err.data.message}`);
      console.log(`error creating application request ${err}`);
      reject(err);
    }
  });
};

export const updateApplicationRequestRecord = (payload: { application_id: number, id: number, status: string }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { id } = payload;
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.put(
        `${api_base}/api/application-requests/${id}`,
        {
          data: payload,
          headers: headers,
        },
      );
      if (response.message) {
        Error(response.message);
        resolve(response);
        return;
      }
      Success('Application reques was updated');
      resolve(response);
    } catch (err: any) {
      console.log(`error updateting application ${err}`);
      Error(err.message);
      reject(err);
    }
  });
};

export const checkApplicationStatusCall = (payload: { id_number: string} ) => {
  console.log(payload)
  console.log(payload.id_number)
  return new Promise(async (resolve, reject) => {
    try {
      const data = await httpRequest.get(
        `${api_base}/api/application-requests/application-status?id_number=${payload.id_number}`,
      );
      resolve(data);
    } catch (err: any) {
      if (err?.data) {
        const { statusCode } = err.data;
        if (statusCode === 404) {
          resolve(null);
          return;
        }
      }
      reject(err);
    }
  });
};

export const getApplicationRequest = async (
  applicationRequestId: number,
): Promise<Data<ApplicationRequest>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const response = await httpRequest.get<ApplicationRequest>(
        `${api_base}/api/application-requests/${applicationRequestId}`,
      );

      console.log(response);

      resolve(response);
    } catch (err: any) {
      console.log(`error calling get application request endpoint ${err}`);
      if (err?.data) {
        const { statusCode } = err.data;
        if (statusCode === 404) {
          resolve(null);
          return;
        }
      }
      reject(err);
    }
  });
};
