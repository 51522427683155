import { WardConsilor } from '@/domain/ward-consilor';
import { Action, Data, HttpTypes, ServerData } from '@/types';
import {
  createWardConsilorRecord,
  deleteWardConsilorRequest,
  fetchWardConsilor,
  putWardConsilor,
} from '@/pages/wardConsilor/services/wardConsilor.service';
import {
  WARDCAONSILOR_FAILURE,
  WARDCAONSILOR_SUCCESS,
} from '@/pages/wardConsilor/state/actions/actionTypes';
import { handleServerErrors } from '@/pages/common/state/effects';

export function* createWardConsilor(
  { payload }: Action<Data<WardConsilor>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: WardConsilor = yield call(createWardConsilorRecord, payload);
    yield put({
      type: WARDCAONSILOR_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<WardConsilor>>(
      err,
      put,
      call,
      WARDCAONSILOR_FAILURE,
    );
  }
}

export function* getWardConsilor(
  { payload }: Action<Data<any>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: WardConsilor = yield call(fetchWardConsilor, payload);
    yield put({
      type: WARDCAONSILOR_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleServerErrors<Data<WardConsilor>>(
      err,
      put,
      call,
      WARDCAONSILOR_FAILURE,
    );
  }
}

export function* updateWardConsilor(
  { payload }: Action<Data<WardConsilor>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: WardConsilor = yield call(putWardConsilor, payload);
    yield put({
      type: WARDCAONSILOR_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<WardConsilor>>(
      err,
      put,
      call,
      WARDCAONSILOR_FAILURE,
    );
  }
}

export function* deleteWardConsilor(
  { payload }: Action<Data<WardConsilor>>,
  { call, put }: { call: any; put: any },
) {
  try {
    console.log(payload);
    const data: WardConsilor = yield call(deleteWardConsilorRequest, payload);
    yield put({
      type: WARDCAONSILOR_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<WardConsilor>>(
      err,
      put,
      call,
      WARDCAONSILOR_FAILURE,
    );
  }
}
