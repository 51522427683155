import { InitialState } from './types';
import { UserRole } from '@/domain/enums/user-roles';
import { AccessControlList } from './domain/access-control-list';

export default function (initialState: InitialState) {
  const acl: AccessControlList = {
    canAddApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.INDIGENT_OFFICE) ||
      initialState?.roles?.includes(UserRole.ROLE_MANAGER),
    canViewApplication: initialState?.roles?.includes(UserRole.ROLE_USER),
    canUpdateApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.INDIGENT_OFFICE) ||
      initialState?.roles?.includes(UserRole.ROLE_MANAGER),
    canDeleteApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.ROLE_MANAGER),
    canListApplication: initialState?.roles?.includes(UserRole.ROLE_USER),

    canOverrideApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.ROLE_MANAGER),

    canReviewApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.ROLE_MANAGER) ||
      initialState?.roles?.includes(UserRole.ROLE_COUNCILLOR),
    canApproveApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.ROLE_MANAGER),
    canAuditApplication:
      initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
      initialState?.roles?.includes(UserRole.ROLE_AUDITOR),
    canAdminUsers: initialState?.roles?.includes(UserRole.ROLE_ADMIN),
    canViewVerifications:
    initialState?.roles?.includes(UserRole.ROLE_ADMIN) ||
    initialState?.roles?.includes(UserRole.ROLE_MANAGER),
  };
  return acl;
}
