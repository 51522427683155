import { Data } from '@/types';
import { AdultHouseholdProfile } from '@/domain/adult-household-profile';
import { failure, success } from '@/pages/adultHousehold/state/reducers';
import { create, get, update } from '@/pages/adultHousehold/state/effects';

export default {
  namespace: 'adultHousehold',
  state: {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<AdultHouseholdProfile>,
  reducers: {
    success,
    failure,
  },
  effects: {
    create,
    get,
    update,
  },
};
