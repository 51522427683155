import { CustomerAssetProfile } from '@/domain/customer-asset-profile';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';

export const CreateCustomerAssetProfile = (payload: CustomerAssetProfile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { customer } = payload;
      const existing = await GetByCustomerId(customer?.id);
      if (existing) {
        resolve(existing);
      } else {
        const headers = new Headers({ 'Content-Type': 'application/json' });
        const response = await httpRequest.post(
          `${api_base}/api/customer-asset-profiles`,
          {
            data: payload,
            headers: headers,
          },
        );
        resolve(response);
      }
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const UpdateCustomerAssetProfile = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.put(
        `${api_base}/api/customer-asset-profiles`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const GetByCustomerId = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.get(
        `${api_base}/api/customer-asset-profiles/customer/${payload}`,
        {
          headers: headers,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};
