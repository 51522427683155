import { Action, Data, HttpTypes } from '@/types';
import {
  createProperty,
  fetchWardConsilors,
  fetchStandTypes,
  fetchProperty,
  updateProperty,
  putGrantHouseholdProfile,
  fetchGrantHousehold,
  fetchMunicipalServices,
  fetchByCustomerProperty,
} from '@/pages/property/services';
import {
  PROPERTY_CREATE_FAILURE,
  PROPERTY_CREATE_SUCCESS,
  PROPERTY_CLEAR_ERRORS,
  PROPERTY_WARDCONSILOR_SUCCESS,
  PROPERTY_STANDTYPES_SUCCESS,
  PROPERTY_FETCH_REQUEST_SUCCESS,
  GRANDHOUSEHOLD_PUT_SUCCESS,
  PROPERTY_MUNICIPAL_SERVICES_SUCCESS,
} from '@/pages/property/state/actions/actionTypes';
import { Property } from '@/domain/property';
import { WardConsilor } from '@/domain/ward-consilor';
import { StandType } from '@/domain/stand-type';
import { GrantsHouseholdProfile } from '@/domain/grants-household-profile';
import { handleServerErrors } from '@/pages/common/state/effects';
import { MunicipalService } from '@/domain/municipal-service';

export function* create(
  { payload }: Action<Data<Property>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Property>> = yield call(
      createProperty,
      payload,
    );
    yield put({
      type: PROPERTY_CREATE_SUCCESS,
      payload: data,
    });
    yield put({
      type: PROPERTY_CLEAR_ERRORS,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<Property>>(err, put, call, PROPERTY_CREATE_FAILURE);
  }
}

export function* update(
  { payload }: Action<Data<Property>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Property>> = yield call(
      updateProperty,
      payload,
    );
    yield put({
      type: PROPERTY_CREATE_SUCCESS,
      payload: data,
    });
    yield put({
      type: PROPERTY_CLEAR_ERRORS,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<Property>>(err, put, call, PROPERTY_CREATE_FAILURE);
  }
}

export function* getWardConsilors(
  { payload }: Action<Data<WardConsilor>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<WardConsilor[]>> = yield call(
      fetchWardConsilors,
      {},
    );
    yield put({
      type: PROPERTY_WARDCONSILOR_SUCCESS,
      payload: data.data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<WardConsilor>>(
      err,
      put,
      call,
      PROPERTY_CREATE_FAILURE,
    );
  }
}

export function* getStandTypes(
  { payload }: Action<Data<StandType[]>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<StandType[]>> = yield call(
      fetchStandTypes,
      {},
    );
    yield put({
      type: PROPERTY_STANDTYPES_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<StandType>>(
      err,
      put,
      call,
      PROPERTY_CREATE_FAILURE,
    );
  }
}

export function* getProperty(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Property>> = yield call(
      fetchProperty,
      payload,
    );
    yield put({
      type: PROPERTY_FETCH_REQUEST_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<Property>>(err, put, call, PROPERTY_CREATE_FAILURE);
  }
}

export function* getByCustomerProperty(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Property>> = yield call(
      fetchByCustomerProperty,
      payload,
    );
    yield put({
      type: PROPERTY_FETCH_REQUEST_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<Property>>(err, put, call, PROPERTY_CREATE_FAILURE);
  }
}

export function* updateGrantHouseholdProfile(
  { payload }: Action<GrantsHouseholdProfile>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<GrantsHouseholdProfile>
    > = yield call(putGrantHouseholdProfile, payload);
    yield put({
      type: GRANDHOUSEHOLD_PUT_SUCCESS,
      payload: data,
    });
    yield put({
      type: PROPERTY_CLEAR_ERRORS,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<GrantsHouseholdProfile>>(
      err,
      put,
      call,
      PROPERTY_CREATE_FAILURE,
    );
  }
}

export function* getGranthouseholdProfile(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<GrantsHouseholdProfile>
    > = yield call(fetchGrantHousehold, payload);
    yield put({
      type: GRANDHOUSEHOLD_PUT_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<Property>>(err, put, call, PROPERTY_CREATE_FAILURE);
  }
}

export function* getMunicipalServices(
  { payload }: Action<Data<MunicipalService>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<MunicipalService[]>
    > = yield call(fetchMunicipalServices);
    yield put({
      type: PROPERTY_MUNICIPAL_SERVICES_SUCCESS,
      payload: data.data,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<MunicipalService>>(
      err,
      put,
      call,
      PROPERTY_CREATE_FAILURE,
    );
  }
}
