import { routerRedux } from 'dva';
import { getUserInfo, loginAPI } from '@/pages/login/services/auth.service';
import { AuthStatus } from '@/domain/enums/auth-status';
import { Auth } from '@/types';
import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_SUCCESS,
} from '@/pages/login/state/actions/actionsTypes';

export function* login(
  { payload }: any,
  { call, put }: any,
): Generator<any, any, Auth> {
  try {
    const response: any = yield call(loginAPI, payload);
    let result: Auth = {
      authStatus: AuthStatus.UNINITIALIZED,
    };
    if (response) {
      const { data, type, name, id_token } = response;
      if (id_token && String(id_token).length > 0) {
        result.authStatus = AuthStatus.AUTHENTICATED;
        result.id_token = id_token;
        yield put({
          type: AUTH_LOGIN_SUCCESS,
          payload: result,
        });
        localStorage.setItem('api_token', String(id_token));
        const user: any = yield call(getUserInfo);
        localStorage.setItem('userinfo', JSON.stringify(user));
        return result;
      }

      const { statusCode } = data;
      if (statusCode >= 200 && statusCode <= 210) {
        const { message } = data;
        result.authStatus = AuthStatus.AUTHENTICATED;
        result.id_token = message;
        result.message = message;
        yield put({
          type: AUTH_LOGIN_SUCCESS,
          payload: result,
        });
        return result;
      }

      if (statusCode >= 400 && statusCode < 500) {
        const { message } = data;
        result.authStatus = AuthStatus.ERRORED;
        result.message = message;
        yield put({
          type: AUTH_LOGIN_FAILURE,
          payload: result,
        });
        return result;
      }

      if (statusCode >= 500) {
        const { message } = data;
        result.authStatus = AuthStatus.ERRORED;
        result.message = message;
        yield put({
          type: AUTH_LOGIN_FAILURE,
          payload: result,
        });
        return result;
      }
    }
    result.authStatus = AuthStatus.ERRORED;
    result.message = 'Communciation Error';
    yield put({
      type: AUTH_LOGIN_FAILURE,
      payload: result,
    });
    return result;
  } catch (err) {
    const result: Auth = {
      authStatus: AuthStatus.ERRORED,
      message: `Error: ${err}`,
    };
    yield put({
      type: AUTH_LOGIN_FAILURE,
      payload: result,
    });
  }
}

export function* loginExpired(
  { payload }: any,
  { put }: any,
): Generator<any, void, any> {
  localStorage.removeItem('api_token');
  const auth: Auth = {
    authStatus: AuthStatus.UNINITIALIZED,
    message: 'Session Expired',
  };
  yield put({
    type: AUTH_LOGIN_FAILURE,
    payload: auth,
  });
  yield put(routerRedux.push(`/login`));
}

export function* checkToken({ payload }: any, { put }: any) {
  const token = localStorage.getItem('api_token') || undefined;
  if (token) {
    const auth: Auth = {
      authStatus: AuthStatus.AUTHENTICATED,
      id_token: token,
    };
    yield put({
      type: AUTH_LOGIN_SUCCESS,
      payload: auth,
    });
  } else {
    const auth: Auth = {
      authStatus: AuthStatus.UNINITIALIZED,
      id_token: token,
      message: 'Session Expired',
    };
    yield put({
      type: AUTH_LOGIN_FAILURE,
      payload: auth,
    });
  }
}
