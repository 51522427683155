import { Customer } from '@/domain/customer';
import { Action, Data, HttpTypes } from '@/types';

export const success = (
  state: Data<Customer>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const clear = (
  state: Data<Customer>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const Record = {} as Customer;
  const ServerData = { Errors: {} };
  return { ...state, Record, ServerData };
};

export const failure = (
  state: Data<Customer>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  state.Record = {} as Customer;
  return { ...state, ServerData };
};


