import { AdultHouseholdProfile } from '@/domain/adult-household-profile';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';

export const getAdultHouseholdProfile = (propertyId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {
        data,
      } = await httpRequest.get(
        `${api_base}/api/adult-household-profiles/property/${propertyId}`,
        { getResponse: true },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ${propertyId} ,  ${err}`);
      reject(err);
    }
  });
};

export const createAdultHouseholdProfile = (payload: AdultHouseholdProfile) => {
  return new Promise(async (resolve, reject) => {
    try {
      const dto = { ...payload };
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.post<AdultHouseholdProfile>(
        `${api_base}/api/adult-household-profiles`,
        {
          data: dto,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const updateAdultHouseholdProfile = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const dto = { ...payload };
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.put<AdultHouseholdProfile>(
        `${api_base}/api/adult-household-profiles/${dto.id}`,
        {
          data: dto.data,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};
