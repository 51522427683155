import { Data } from '@/types';
import {
  success,
  failure,
  clear,
} from '@/pages/applicationDocument/state/reducers';
import {
  createApplicationDocument,
  createApplicationDocumentCheck,
  fetchApplicationDocument,
} from '@/pages/applicationDocument/state/effects';
import { OperationOutCome } from '@/domain/enums/operation-outcome';
import { ApplicationDocument } from '@/domain/application-document';

export default {
  namespace: 'applicationDocument',
  state: {
    Record: [] as ApplicationDocument[],
    FormStatus: {},
    Outcome: OperationOutCome.UNKNOWN,
    ServerData: { Errors: {} },
  } as Data<ApplicationDocument[]>,
  reducers: {
    success,
    failure,
    clear,
  },
  effects: {
    createApplicationDocumentCheck,
    fetchApplicationDocument,
    createApplicationDocument,
  },
};
