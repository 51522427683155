import { AdultHouseholdProfile } from '@/domain/adult-household-profile';
import { Action, Data, HttpTypes } from '@/types';

export const success = (
  state: Data<AdultHouseholdProfile>,
  action: Action<Data<AdultHouseholdProfile>>,
) => {
  const { payload } = action;
  let previous = state;
  Object.assign(previous.Record, payload);
  return { ...state, previous };
};

export const failure = (
  state: Data<AdultHouseholdProfile>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  return { ...state, ServerData };
};
