// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/app/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/app/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__login__index' */'/app/src/layouts/login/index'), loading: LoadingComponent}),
        "menuRender": false,
        "headerRender": false,
        "routes": [
          {
            "path": "/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/selfhelp",
        "menuRender": false,
        "headerRender": false,
        "routes": [
          {
            "path": "/selfhelp/create-application",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__selfhelp__application' */'@/pages/selfhelp/application'), loading: LoadingComponent}),
            "name": "Selfhelp Application",
            "exact": true
          },
          {
            "path": "/selfhelp/application-status-check",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__selfhelp__application__status-request' */'@/pages/selfhelp/application/status-request'), loading: LoadingComponent}),
            "name": "Selfhelp Application Status Check",
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
        "headerRender": true,
        "menu": {
          "name": "Main",
          "flatMenu": true,
          "hideInMenu": false,
          "hideChildrenInMenu": false
        },
        "routes": [
          {
            "path": "/",
            "redirect": "/dashboard",
            "exact": true
          },
          {
            "path": "/dashboard",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__dashboard__' */'@/pages/dashboard/'), loading: LoadingComponent}),
            "menu": {
              "name": "Dashboard",
              "icon": "DashboardTwoTone",
              "flatMenu": false,
              "hideInMenu": false,
              "hideChildrenInMenu": false
            },
            "exact": true
          },
          {
            "path": "/analytics",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__analytics__' */'@/pages/analytics/'), loading: LoadingComponent}),
            "menu": {
              "name": "Analytics",
              "icon": "FundTwoTone",
              "flatMenu": false,
              "hideInMenu": false,
              "hideChildrenInMenu": false
            },
            "exact": true
          },
          {
            "path": "/customer-applications",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
            "menu": {
              "name": "Applications",
              "flatMenu": false,
              "icon": "AppstoreTwoTone",
              "hideInMenu": false,
              "hideChildrenInMenu": true
            },
            "routes": [
              {
                "path": "/customer-applications/new",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__create' */'@/pages/application/create'), loading: LoadingComponent}),
                "menu": {
                  "name": "New Customer Application",
                  "flatMenu": false,
                  "hideInMenu": false,
                  "hideChildrenInMenu": false
                },
                "exact": true
              },
              {
                "path": "/customer-applications/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__' */'@/pages/application/'), loading: LoadingComponent}),
                "menu": {
                  "name": "View Applications",
                  "flatMenu": false,
                  "hideInMenu": false,
                  "hideChildrenInMenu": false
                },
                "exact": true
              },
              {
                "path": "/customer-applications/requests",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__requests' */'@/pages/application/requests'), loading: LoadingComponent}),
                "name": "Application Requests",
                "hideInMenu": false,
                "exact": true
              },
              {
                "path": "/customer-applications/requests/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__request-view' */'@/pages/application/request-view'), loading: LoadingComponent}),
                "name": "View Application Request",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer-applications/reports",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
                "name": "Application Reports",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer-applications/view/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__view' */'@/pages/application/view'), loading: LoadingComponent}),
                "name": "View Application",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/customer-applications/edit/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__application__edit' */'@/pages/application/edit'), loading: LoadingComponent}),
                "name": "Edit Application",
                "hideInMenu": true,
                "exact": true
              }
            ]
          },
          {
            "path": "/verifications",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__verifications__' */'@/pages/verifications/'), loading: LoadingComponent}),
            "menu": {
              "name": "Verifications",
              "icon": "FolderTwoTone",
              "flatMenu": false,
              "hideInMenu": false,
              "hideChildrenInMenu": false
            },
            "exact": true
          },
          {
            "path": "/verifications/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__verifications__view' */'@/pages/verifications/view'), loading: LoadingComponent}),
            "name": "View Verification",
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/asset-profile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/asset-profile/customer/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__assetProfile__create' */'@/pages/assetProfile/create'), loading: LoadingComponent}),
                "menu": {
                  "name": "Customer Asset Profile",
                  "flatMenu": false,
                  "hideInMenu": false,
                  "hideChildrenInMenu": false
                },
                "exact": true
              }
            ]
          },
          {
            "path": "/property",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/property/customer/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__property__create' */'@/pages/property/create'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/property/edit/:id",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__property__edit' */'@/pages/property/edit'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/master-data",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/master-data/ward-consilors",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__masterData__wardConsilor' */'@/pages/masterData/wardConsilor'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "path": "/reports",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports' */'@/pages/reports'), loading: LoadingComponent}),
            "menu": {
              "name": "Reports",
              "flatMenu": false,
              "icon": "CopyTwoTone",
              "hideInMenu": true,
              "hideChildrenInMenu": false
            },
            "exact": true
          },
          {
            "path": "/reports/application-by-ward",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByWard' */'@/pages/reports/applicationByWard'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-ward",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByWard' */'@/pages/reports/applicationByWard'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-region",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByRegion' */'@/pages/reports/applicationByRegion'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-user",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByUser' */'@/pages/reports/applicationByUser'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-region-by-financial-year",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByRegionByFinancialYear' */'@/pages/reports/applicationByRegionByFinancialYear'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-ward-financial-year",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByWardByFinancialYear' */'@/pages/reports/applicationByWardByFinancialYear'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-status-financial-year",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByStatusByFinancialYear' */'@/pages/reports/applicationByStatusByFinancialYear'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-by-reference",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationByReference' */'@/pages/reports/applicationByReference'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/declined-applications",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__declinedApplications' */'@/pages/reports/declinedApplications'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/household-profile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__householdProfile' */'@/pages/reports/householdProfile'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/application-date-to-status-age",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__applicationAgeByStatus' */'@/pages/reports/applicationAgeByStatus'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/indigent-below-amount",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__indigentBelowAmount' */'@/pages/reports/indigentBelowAmount'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/verified-reports",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__verified__' */'@/pages/reports/verified/'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "exact": true
          },
          {
            "path": "/reports/verified-reports",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__reports__verified__' */'@/pages/reports/verified/'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/reports/verified-reports/2021/PSJ _INDIGENTS FILE_2021.xlsx",
            "exact": true
          },
          {
            "path": "/admin",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
            "access": "canAdminUsers",
            "menu": {
              "name": "Administration",
              "flatMenu": false,
              "icon": "LockTwoTone",
              "hideInMenu": false,
              "hideChildrenInMenu": true
            },
            "routes": [
              {
                "path": "/admin/users",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
                "access": "canAdminUsers",
                "menu": {
                  "name": "Users",
                  "icon": "UserOutlined",
                  "flatMenu": false,
                  "hideInMenu": false,
                  "hideChildrenInMenu": false
                },
                "routes": [
                  {
                    "path": "/admin/users/view",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__users__list' */'@/pages/admin/users/list'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {
                      "name": "View users",
                      "flatMenu": true,
                      "hideInMenu": true,
                      "hideChildrenInMenu": false
                    },
                    "exact": true
                  },
                  {
                    "path": "/admin/users/new",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__users__create__CreateUser' */'@/pages/admin/users/create/CreateUser'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {
                      "name": "New user",
                      "flatMenu": false,
                      "hideInMenu": false,
                      "hideChildrenInMenu": false
                    },
                    "exact": true
                  },
                  {
                    "path": "/admin/users/edit/:username",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__admin__users__edit__EditUser' */'@/pages/admin/users/edit/EditUser'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/admin/municipal-services",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
                "access": "canAdminUsers",
                "menu": {
                  "name": "Municipal Services",
                  "flatMenu": false,
                  "hideInMenu": false,
                  "hideChildrenInMenu": false
                },
                "routes": [
                  {
                    "path": "/admin/municipal-services/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__municipalService__list' */'@/pages/municipalService/list'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {
                      "name": "View municipal services",
                      "flatMenu": false,
                      "hideInMenu": false,
                      "hideChildrenInMenu": false
                    },
                    "exact": true
                  },
                  {
                    "path": "/admin/municipal-services/new",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__municipalService__create' */'@/pages/municipalService/create'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {
                      "name": "New municipal service",
                      "flatMenu": false,
                      "hideInMenu": false,
                      "hideChildrenInMenu": false
                    },
                    "exact": true
                  },
                  {
                    "path": "/admin/municipal-services/edit/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__municipalService__edit' */'@/pages/municipalService/edit'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "exact": true
                  },
                  {
                    "path": "/admin/municipal-services/view/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__municipalService__view' */'@/pages/municipalService/view'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "exact": true
                  }
                ]
              },
              {
                "path": "/admin/ward-councillor",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__app__' */'/app/src/layouts/app/'), loading: LoadingComponent}),
                "access": "canAdminUsers",
                "menu": {
                  "name": "Ward Councillor",
                  "flatMenu": false,
                  "icon": "AppstoreTwoTone",
                  "hideInMenu": false,
                  "hideChildrenInMenu": true
                },
                "routes": [
                  {
                    "path": "/admin/ward-councillor/list",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wardConsilor__' */'@/pages/wardConsilor/'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {
                      "name": "View Ward Councillor",
                      "flatMenu": false,
                      "hideInMenu": false,
                      "hideChildrenInMenu": false
                    },
                    "exact": true
                  },
                  {
                    "path": "/admin/ward-councillor/new",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wardConsilor__create' */'@/pages/wardConsilor/create'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {
                      "name": "New Ward Councillor",
                      "flatMenu": false,
                      "hideInMenu": false,
                      "hideChildrenInMenu": false
                    },
                    "exact": true
                  },
                  {
                    "path": "/admin/ward-councillor/view/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wardConsilor__view' */'@/pages/wardConsilor/view'), loading: LoadingComponent}),
                    "access": "canAdminUsers",
                    "menu": {},
                    "exact": true
                  },
                  {
                    "path": "/admin/ward-councillor/edit/:id",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wardConsilor__edit' */'@/pages/wardConsilor/edit'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
