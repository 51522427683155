import { Application, ApplicationIncomeBelow } from '@/domain/application';
import { ApplicationByStatusByFinancialYear } from '@/domain/application-by-status-by-financial-year';
import { ApplicationByStatusByFinancialYearFilter } from '@/domain/application-by-status-by-financial-year-filter';
import { ApplicationByUserFilter } from '@/domain/application-by-user-filter';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';
import { Data } from '@/types';
import { RequestData } from '@ant-design/pro-table/lib/typing';

export const getApplicationByWardRegionRequest = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const endpoint = `${api_base}/api/applications/report/applicationByWardRegion?page=${page}&size=${pageSize}&sort=createdDate`;
      let endpointFilters = '';
      if (filter.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${filter.startDate}`;
      }
      if (filter.endDate) {
        endpointFilters = `${endpointFilters}&endDate=${filter.endDate}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(`${endpoint}${endpointFilters}`, {
        getResponse: true,
      });
      const result: RequestData<any> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByWardRegionReportRequest = async (
  payload: any,
): Promise<Data<Application>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const endpoint = `${api_base}/api/applications/report/applicationByWardRegionReport?`;
      let endpointFilters = '';
      if (payload.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${payload.startDate}`;
      }
      if (payload.endDate) {
        endpointFilters = `${endpointFilters}&endDate=${payload.endDate}`;
      }

      const data = await httpRequest.get<Application>(
        `${endpoint}${endpointFilters}`,
      );
      resolve(data);
    } catch (err) {
      console.log(`error calling get application endpoint ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByUserReport = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: ApplicationByUserFilter,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationByUser?page=${params.page}&size=${params.pageSize}&sort=createdDate`;
      if (filter?.username) {
        endpoint += `&username=${filter.username}`;
      }

      if (filter?.startDate) {
        endpoint += `&startDate=${filter.startDate}`;
      }

      if (filter?.endDate) {
        endpoint += `&endDate=${filter.endDate}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(endpoint, { getResponse: true });
      const result: RequestData<any> = {
        data: data?.map((item: any, index: number) => ({
          ...item,
          index,
          downloadKey: `${item.date}-${index}`,
        })),
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByWardRegionFinancialYearRequest = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const endpoint = `${api_base}/api/applications/report/applicationByWardRegion/byFinancialYear?page=${page}&size=${pageSize}&sort=createdDate`;
      let endpointFilters = '';
      console.log(filter.startDate);
      if (filter.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${filter.startDate}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(`${endpoint}${endpointFilters}`, {
        getResponse: true,
      });
      const result: RequestData<any> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByWardRegionReportFinancialYearReportRequest = async (
  payload: any,
): Promise<Data<Application>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const endpoint = `${api_base}/api/applications/report/applicationByWardRegionReport/byFinancialYear?`;
      let endpointFilters = '';
      if (payload.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${payload.startDate}`;
      }
      if (payload.endDate) {
        endpointFilters = `${endpointFilters}&endDate=${payload.endDate}`;
      }

      const data = await httpRequest.get<Application>(
        `${endpoint}${endpointFilters}`,
      );
      resolve(data);
    } catch (err) {
      console.log(`error calling get application endpoint ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByWard = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const endpoint = `${api_base}/api/applications/report/applicationByWard?page=${page}&size=${pageSize}&sort=createdDate`;
      let endpointFilters = '';
      if (filter.consilorId) {
        endpointFilters = `${endpointFilters}&wardconsilor=${filter.consilorId}`;
      }
      if (filter.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${filter.startDate}`;
      }
      if (filter.endDate) {
        endpointFilters = `${endpointFilters}&endDate=${filter.endDate}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(`${endpoint}${endpointFilters}`, {
        getResponse: true,
      });
      const result: RequestData<any> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByUserFullReport = async (
  filter: ApplicationByUserFilter,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationByUserReport?`;
      if (filter?.username) {
        endpoint += `&username=${filter.username}`;
      }

      if (filter?.startDate) {
        endpoint += `&date=${filter.startDate}`;
      }
      const data = await httpRequest.get(endpoint, { getResponse: true });
      resolve(data);
    } catch (err) {
      console.log(`error getting application by user full report ,  ${err}`);
      reject(err);
    }
  });
};
export const getApplicationByWardReportRequest = async (
  payload: any,
): Promise<Data<Application>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      let endpointFilters = '';
      if (payload.consilorId) {
        endpointFilters = `${endpointFilters}&wardconsilor=${payload.consilorId}`;
      }
      if (payload.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${payload.startDate}`;
      }
      if (payload.endDate) {
        endpointFilters = `${endpointFilters}&endDate=${payload.endDate}`;
      }

      const data = await httpRequest.get<Application>(
        `${api_base}/api/applications/report/applicationByWardReport?${endpointFilters}`,
      );
      resolve(data);
    } catch (err) {
      console.log(`error calling get application endpoint ${err}`);
      reject(err);
    }
  });
};

export const fetchUsers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await httpRequest.get(`${api_base}/api/users`);
      resolve(data);
    } catch (err) {
      console.log(`error getting municipal services,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByWardByFincialYear = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const endpoint = `${api_base}/api/applications/report/applicationByWard/byFinancialYear?page=${page}&size=${pageSize}&sort=createdDate`;
      let endpointFilters = '';
      if (filter.consilorId) {
        endpointFilters = `${endpointFilters}&wardconsilor=${filter.consilorId}`;
      }
      if (filter.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${filter.startDate}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(`${endpoint}${endpointFilters}`, {
        getResponse: true,
      });
      const result: RequestData<any> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByWardByFinancialYearRequest = async (
  payload: any,
): Promise<Data<Application>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      let endpointFilters = '';
      if (payload.consilorId) {
        endpointFilters = `${endpointFilters}&wardconsilor=${payload.consilorId}`;
      }
      if (payload.startDate) {
        endpointFilters = `${endpointFilters}&startDate=${payload.startDate}`;
      }

      const data = await httpRequest.get<Application>(
        `${api_base}/api/applications/report/applicationByWardReport/byFinancialYear?${endpointFilters}`,
      );
      resolve(data);
    } catch (err) {
      console.log(`error calling get application endpoint ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByStatusbyFinancialYearReport = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: ApplicationByStatusByFinancialYearFilter,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationByStatus/byFinancialYear?page=${params.page}&size=${params.pageSize}&sort=createdDate`;
      if (filter?.status) {
        endpoint += `&status=${filter.status}`;
      }

      if (filter?.financialYear) {
        endpoint += `&financialYear=${filter.financialYear}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(endpoint, { getResponse: true });
      const result: RequestData<ApplicationByStatusByFinancialYear> = {
        data: data?.map(
          (item: ApplicationByStatusByFinancialYear, index: number) => ({
            ...item,
            index,
            downloadKey: `${item.year}-${index}`,
          }),
        ),
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationByStatusbyFinancialYearFullReport = async (
  filter: ApplicationByStatusByFinancialYearFilter,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationByStatus/byFinancialYearReport?`;
      if (filter?.status) {
        endpoint += `&status=${filter.status}`;
      }

      if (filter?.financialYear) {
        endpoint += `&financialYear=${filter.financialYear}`;
      }
      const data = await httpRequest.get(endpoint, { getResponse: true });
      resolve(data);
    } catch (err) {
      console.log(`error getting application by user full report ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationsByReference = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const { pageSize, page } = params;

      let endpoint = `${api_base}/api/applications/report/applicationByReference?page=${page}&size=${pageSize}&sort=createdDate`;

      if (filter?.referenceNumber) {
        endpoint += `&referenceNumber=${filter.referenceNumber.replace(
          '#',
          '',
        )}`;
      }
      const response = await httpRequest.get(endpoint);
      const { data, total } = response;
      const result: RequestData<Application> = {
        data: data,
        success: response ? true : false,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying applications endpoint ${err}`);
      reject(err);
    }
  });
};

export const getApplicationsByReferenceReportRequest = async (
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationByReferenceReport`;
      if (filter?.referenceNumber) {
        endpoint += `?referenceNumber=${filter.referenceNumber.replace(
          '#',
          '',
        )}`;
      }
      if (filter?.status) {
        endpoint += `&status=${filter.status}`;
      }
      const data = await httpRequest.get(endpoint, { getResponse: true });
      resolve(data);
    } catch (err) {
      console.log(`error getting application by user full report ,  ${err}`);
      reject(err);
    }
  });
};

export const getDeclinedApplication = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const { pageSize, page } = params;

      let endpoint = `${api_base}/api/applications/report/declinedApplication?page=${page}&size=${pageSize}&sort=createdDate`;
      const response = await httpRequest.get(endpoint);
      const { data, total } = response;
      const result: RequestData<Application> = {
        data: data,
        success: response ? true : false,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying applications endpoint ${err}`);
      reject(err);
    }
  });
};

export const fetchCustomers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.get(
        `${api_base}/api/applications/report/getAllCustomers`,
        {
          headers: headers,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const getApplicationAgeByStatus = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationAgeByStatus?page=${params.page}&size=${params.pageSize}&sort=createdDate`;
      if (filter?.username) {
        endpoint += `&username=${filter.username}`;
      }
      if (filter?.wardNumber) {
        endpoint += `&wardconsilor=${filter.wardNumber}`;
      }
      if (filter?.status) {
        endpoint += `&status=${filter.status}`;
      }
      if (filter?.startDate) {
        endpoint += `&startDate=${filter.startDate}`;
      }
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(endpoint, { getResponse: true });
      const result: RequestData<any> = {
        data: data?.map((item: any, index: number) => ({
          ...item,
          index,
          downloadKey: `${item.reference}-${index}`,
        })),
        success: ok,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying applications endpoint ${err}`);

      reject(err);
    }
  });
};
export const getIndigentBelowAmount = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<ApplicationIncomeBelow>> => {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      const { pageSize, page } = params;

      let endpoint = `${api_base}/api/applications/report/indigentBelowAmount?page=${page}&size=${pageSize}&sort=createdDate`;
      if (filter?.incomeCategory) {
        endpoint += `&incomeCategory=${filter.incomeCategory}`;
      }
      const response = await httpRequest.get(endpoint);
      const { data, total } = response;
      const result: RequestData<ApplicationIncomeBelow> = {
        data: data,
        success: response ? true : false,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying applications endpoint ${err}`);
      reject(err);
    }
  });
};

export const getApplicationAgeByStatusReportRequest = async (
  filter: any,
): Promise<RequestData<any>> => {
  return new Promise(async (resolve, reject) => {
    try {
      let endpoint = `${api_base}/api/applications/report/applicationAgeByStatusReport?`;
      if (filter?.reference) {
        endpoint += `reference=${filter.reference.replace('#', '')}`;
      }
      if (filter?.status) {
        endpoint += `&status=${filter.status}`;
      }
      const data = await httpRequest.get(endpoint, { getResponse: true });
      resolve(data);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};
export const fetchIndigentBelowAmountDropdown = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const response = await httpRequest.get(
        `${api_base}/api/applications/report/indigentBelowAmountDropdown`,
        {
          headers: headers,
        },
      );
      resolve(response);
    } catch (err) {
      console.log(`error creating customer ${err}`);
      reject(err);
    }
  });
};

export const fetchVerified2021Report = (): Promise<Blob> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { response } = await httpRequest.get(
        `${api_base}/api/applications/report/verified/2021`,
        {
          getResponse: true,
        },
      );
      const blob = await response.blob();
      resolve(blob);
    } catch (err: any) {
      reject(err?.data);
    }
  });
};
