import { httpRequest } from '@/pages/common/interceptors';
import { RequestData } from '@ant-design/pro-table/lib/typing';
import { User } from '@/domain/user';
import { ServerData } from '@/types';
import { api_base } from '@/pages/common/services';

export const query = (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<User>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/admin/users/?page=${page}&size=${pageSize}&sort=email`,
        { getResponse: true },
      );
      const result: RequestData<User> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error fetching users ${err}`);
      reject(err);
    }
  });
};

export const postUser = (payload: User): Promise<User> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.post<User>(
        `${api_base}/api/admin/users`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err: any) {
      console.log(`request error: ${JSON.stringify(err)}`);
      const error = {
        Errors: {
          error: `Error creating user ${payload.email}`,
          message: err?.data?.message,
          statusCode: err?.data?.statusCode,
        },
      } as ServerData;
      reject(error);
    }
  });
};

export const putUserActivated = (payload: {
  userId: number;
  active: boolean;
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { userId, active } = payload;
      const response = await httpRequest.put<User>(
        `${api_base}/api/admin/users/${userId}/${active}`,
      );
      resolve(response);
    } catch (err: any) {
      console.log(`request error: ${JSON.stringify(err)}`);
      const error = {
        Errors: {
          error: `Error changing user's active ${payload.userId}`,
          message: err?.data?.message,
          statusCode: err?.data?.statusCode,
        },
      } as ServerData;
      reject(error);
    }
  });
};

export const getUser = (username: String): Promise<User> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.get<User>(
        `${api_base}/api/admin/users/${username}`,
      );
      resolve(response);
    } catch (err: any) {
      console.log(`request error: ${JSON.stringify(err)}`);
      const error = {
        Errors: {
          error: `Error getting user ${username}`,
          message: err?.data?.message,
          statusCode: err?.data?.statusCode,
        },
      } as ServerData;
      reject(error);
    }
  });
};

export const putUser = (payload: User): Promise<User> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await httpRequest.put<User>(
        `${api_base}/api/admin/users`,
        {
          data: payload,
        },
      );
      resolve(response);
    } catch (err: any) {
      console.log(`request error: ${JSON.stringify(err)}`);
      const error = {
        Errors: {
          error: `Error updating user ${payload.email}`,
          message: err?.data?.message,
          statusCode: err?.data?.statusCode,
        },
      } as ServerData;
      reject(error);
    }
  });
};
