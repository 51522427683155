import { Data } from '@/types';
import { MunicipalService } from '@/domain/municipal-service';
import {
  clearNotify,
  failure,
  getSuccess,
  reset,
  success,
} from '@/pages/municipalService/state/reducers';
import {
  create,
  deleteMunicipalServiceRequest,
  get,
  update,
} from '@/pages/municipalService/state/effects';

export default {
  namespace: 'municipalService',
  state: {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<MunicipalService>,
  reducers: {
    success,
    failure,
    clearNotify,
    getSuccess,
    reset,
  },
  effects: {
    deleteMunicipalServiceRequest,
    create,
    get,
    update,
  },
};
