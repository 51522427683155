import { Action, Data, HttpTypes } from '@/types';
import {
  postOccupant,
  query,
  getOccupant,
  postSocialProfile,
  postOccupantSkill,
  getOccupantSkills,
  getOccupantSocialProfile,
  postMonthlyIncomeProfile,
  getMonthlyIncomeProfile,
  postMonthlyExpenditureProfile,
  getMonthlyExpenditureProfile,
  putMonthlyIncomeProfile,
  putMonthlyExpenditureProfile,
  putOccupant,
  putSocialProfile,
  putOccupantSkill,
  deleteOccupantRequest,
  putOccupantGrant,
} from '@/pages/occupant/services';
import {
  OCCUPANT_CREATE_SUCCESS,
  OCCUPANT_CREATE_FAILURE,
  OCCUPANT_CLEAR_ERRORS,
  OCCUPANT_CREATE_SOCIALPROFILE_SUCCESS,
  OCCUPANT_FETCH_REQUEST_SUCCESS,
  SOCIALPROFILE_FETCH_SUCCESS,
  OCCUPANT_FETCH_REQUEST_FAILURE,
  OCCUPANTSKILL_CREATE_SUCCESS,
  OCCUPANTSKILL_CREATE_FAILURE,
  OCCUPANTSKILLS_GET_SUCCESS,
  OCCUPANTINCOMEPROFILE_SUCCESS,
  OCCUPANTEXPENDITURE_SUCCESS,
  OCCUPANT_UPDATE_SUCCESS,
  OCCUPANTSKILL_UPDATE_SUCCESS,
  OCCUPANT_DELETE_REQUEST_SUCCESS,
} from '@/pages/occupant/state/actions/actionTypes';
import { handleServerErrors } from '@/pages/common/state/effects';
import { Occupant } from '@/domain/occupant';
import { SocialProfile } from '@/domain/social-profile';
import { OccupantSkill } from '@/domain/occupant-skill';
import { MonthlyIncomeProfile } from '@/domain/monthly-income-profile';
import { MonthlyExpenditureProfile } from '@/domain/monthly-expenditure-profile';

export function* create(
  { payload }: Action<Data<Occupant>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Occupant>> = yield call(
      postOccupant,
      payload,
    );
    yield put({
      type: OCCUPANT_CREATE_SUCCESS,
      payload: data,
    });
    yield put({
      type: OCCUPANT_CLEAR_ERRORS,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<Occupant>>(err, put, call, OCCUPANT_CREATE_FAILURE);
  }
}

export function* update(
  { payload }: Action<Occupant>,
  { call, put }: { call: any; put: any },
) {
  try {
    const response: HttpTypes.RequestResponse<Data<Occupant>> = yield call(
      putOccupant,
      payload,
    );
    yield put({
      type: OCCUPANT_UPDATE_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: OCCUPANT_CLEAR_ERRORS,
    });
    return response.data;
  } catch (err: any) {
    handleServerErrors<Data<Occupant>>(err, put, call, OCCUPANT_CREATE_FAILURE);
  }
}

export function* createSocialProfile(
  { payload }: Action<Data<SocialProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<SocialProfile>> = yield call(
      postSocialProfile,
      payload,
    );
    yield put({
      type: OCCUPANT_CREATE_SOCIALPROFILE_SUCCESS,
      payload: data,
    });
    yield put({
      type: OCCUPANT_CLEAR_ERRORS,
    });
    return data;
  } catch (err: any) {
    handleServerErrors<Data<SocialProfile>>(
      err,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* updateSocialProfile(
  { payload }: Action<Data<SocialProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<SocialProfile>> = yield call(
      putSocialProfile,
      payload,
    );
    if (data) {
      yield put({
        type: OCCUPANT_CREATE_SOCIALPROFILE_SUCCESS,
        payload: data,
      });
      yield put({
        type: OCCUPANT_CLEAR_ERRORS,
      });
      return data;
    } else {
      const err = Error('Error updating social profile');
      handleServerErrors<Data<SocialProfile>>(
        err,
        put,
        call,
        OCCUPANT_CREATE_FAILURE,
      );
    }
  } catch (err: any) {
    handleServerErrors<Data<SocialProfile>>(
      err,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* fetchOccupant(
  { payload }: Action<Number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Occupant>> = yield call(
      getOccupant,
      payload,
    );
    yield put({
      type: OCCUPANT_FETCH_REQUEST_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error: any) {
    handleServerErrors<Data<SocialProfile>>(
      error,
      put,
      call,
      OCCUPANT_FETCH_REQUEST_FAILURE,
    );
  }
}

export function* createOccupantSkill(
  { payload }: Action<OccupantSkill>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<OccupantSkill>> = yield call(
      postOccupantSkill,
      payload,
    );
    yield put({ type: OCCUPANTSKILL_CREATE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<SocialProfile>>(
      error,
      put,
      call,
      OCCUPANTSKILL_CREATE_FAILURE,
    );
  }
}

export function* updateOccupantSkill(
  { payload }: Action<OccupantSkill>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<OccupantSkill>> = yield call(
      putOccupantSkill,
      payload,
    );
    yield put({ type: OCCUPANTSKILL_UPDATE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<SocialProfile>>(
      error,
      put,
      call,
      OCCUPANTSKILL_CREATE_FAILURE,
    );
  }
}

export function* fetchOccupantSocialProfile(
  { payload }: Action<Number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<SocialProfile>> = yield call(
      getOccupantSocialProfile,
      payload,
    );
    yield put({
      type: SOCIALPROFILE_FETCH_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error: any) {
    handleServerErrors<Data<SocialProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* fetchOccupantSkills(
  { payload }: Action<Number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<SocialProfile>> = yield call(
      getOccupantSkills,
      payload,
    );
    yield put({
      type: OCCUPANTSKILLS_GET_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error: any) {
    handleServerErrors<Data<SocialProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* createMonthlyIncomeProfile(
  { payload }: Action<MonthlyIncomeProfile>,
  { call, put }: { call: any; put: any },
) {
  try {
    const { occupant } = payload;
    const { id } = occupant;
    const data: HttpTypes.RequestResponse<
      Data<MonthlyIncomeProfile>
    > = yield call(postMonthlyIncomeProfile, id, payload);
    yield put({ type: OCCUPANTINCOMEPROFILE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<MonthlyIncomeProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* updateMonthlyIncomeProfile(
  { payload }: Action<MonthlyIncomeProfile>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<MonthlyIncomeProfile>
    > = yield call(putMonthlyIncomeProfile, payload);
    yield put({ type: OCCUPANTINCOMEPROFILE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<MonthlyIncomeProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* fetchMonthlyIncomeProfile(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<MonthlyIncomeProfile>
    > = yield call(getMonthlyIncomeProfile, payload);
    yield put({ type: OCCUPANTINCOMEPROFILE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<MonthlyIncomeProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* createMonthlyExpenditureProfile(
  { payload }: Action<MonthlyExpenditureProfile>,
  { call, put }: { call: any; put: any },
) {
  try {
    const { occupant } = payload;
    const { id } = occupant;
    const data: HttpTypes.RequestResponse<
      Data<MonthlyExpenditureProfile>
    > = yield call(postMonthlyExpenditureProfile, id, payload);
    yield put({ type: OCCUPANTEXPENDITURE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<MonthlyExpenditureProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* updateMonthlyExpenditureProfile(
  { payload }: Action<MonthlyExpenditureProfile>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<MonthlyExpenditureProfile>
    > = yield call(putMonthlyExpenditureProfile, payload);
    yield put({ type: OCCUPANTEXPENDITURE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<MonthlyExpenditureProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* fetchMonthlyExpenditureProfile(
  { payload }: Action<number>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      Data<MonthlyExpenditureProfile>
    > = yield call(getMonthlyExpenditureProfile, payload);
    yield put({ type: OCCUPANTEXPENDITURE_SUCCESS, payload: data });
  } catch (error: any) {
    handleServerErrors<Data<MonthlyExpenditureProfile>>(
      error,
      put,
      call,
      OCCUPANT_CREATE_FAILURE,
    );
  }
}

export function* deleteOccupant(
  { payload }: Action<Data<Occupant>>,
  { call, put }: { call: any; put: any },
) {
  try {
    console.log(payload);
    const data: Occupant = yield call(deleteOccupantRequest, payload);
    yield put({
      type: OCCUPANT_DELETE_REQUEST_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleServerErrors<Data<Occupant>>(
      err,
      put,
      call,
      OCCUPANT_DELETE_REQUEST_SUCCESS,
    );
  }
}

export function* updateOccupantGrant(
  { payload }: Action<Occupant>,
  { call, put }: { call: any; put: any },
) {
  try {
    const response: HttpTypes.RequestResponse<Data<Occupant>> = yield call(
      putOccupantGrant,
      payload,
    );
    yield put({
      type: OCCUPANT_UPDATE_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: OCCUPANT_CLEAR_ERRORS,
    });
    return response.data;
  } catch (err: any) {
    handleServerErrors<Data<Occupant>>(err, put, call, OCCUPANT_CREATE_FAILURE);
  }
}
