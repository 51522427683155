import { ApplicationsByDate } from '@/domain/applications-by-date';
import { ApplicationsByStatus } from '@/domain/applications-by-status';
import { ApplicationsByWard } from '@/domain/applications-by-ward';
import { CustomerByExpenditure } from '@/domain/customer-expenditure-group-dashboard';
import { CustomerByIncome } from '@/domain/customer-income-group-dashboard';
import { PropertyByWardConsilor } from '@/domain/property-wardconsilor-dashboard';
import { httpRequest } from '@/pages/common/interceptors';
const api_base = process.env.UMI_APP_API_BASE;

export const getPropertyByWardDashboardRequest: any = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/properties/dashboard/wardconsilor`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as PropertyByWardConsilor[]);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getcustomerByIncomeGroupDashboardRequest: any = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/customers/dashboard/incomegroup`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as CustomerByIncome[]);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getcustomerByExpenditureGroupDashboardRequest: any = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/customers/dashboard/expendituregroup`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as CustomerByExpenditure[]);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationsByDateRequest = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/applications/dashboard/${payload.start}/${payload.end}`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as ApplicationsByDate[]);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationsByStatusRequest = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/applications/dashboard/status`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as ApplicationsByStatus[]);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};

export const getApplicationsByWardRequest = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, response } = await httpRequest.get(
        `${api_base}/api/applications/dashboard/ward`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data as ApplicationsByWard[]);
    } catch (err) {
      console.log(`error getting dashboard ,  ${err}`);
      reject(err);
    }
  });
};
