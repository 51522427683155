const namespace = 'occupant';
/*
 * Occupant
 */
export const OCCUPANT_CREATE_REQUEST = `${namespace}/create`;
export const OCCUPANT_CREATE_SUCCESS = `${namespace}/createSuccess`;
export const OCCUPANT_CREATE_FAILURE = `${namespace}/createFailure`;
export const OCCUPANT_UPDATE_REQUEST = `${namespace}/update`;
export const OCCUPANT_UPDATE_GRANT_REQUEST = `${namespace}/updateOccupantGrant`;
export const OCCUPANT_UPDATE_SUCCESS = `${namespace}/updateSuccess`;
export const OCCUPANT_UPDATE_FAILURE = `${namespace}/updateFailure`;
export const OCCUPANT_RESET = `${namespace}/reset`;
export const OCCUPANT_FETCH_REQUEST = `${namespace}/getOccupant`;
export const OCCUPANT_FETCH_REQUEST_SUCCESS = `${namespace}/getOccupantSuccess`;
export const OCCUPANT_FETCH_REQUEST_FAILURE = `${namespace}/getOccupantFailure`;

export const OCCUPANT_DELETE_REQUEST = `${namespace}/deleteOccupant`;
export const OCCUPANT_DELETE_REQUEST_SUCCESS = `${namespace}/deleteOccupantSuccess`;
export const OCCUPANT_DELETE_REQUEST_FIALURE = `${namespace}/deleteOccupantFailure`;
/**
 * Occupant Social Profile
 */
export const OCCUPANT_CREATE_SOCIALPROFILE_REQUEST = `${namespace}/createSocialProfile`;
export const OCCUPANT_UPDATE_SOCIALPROFILE_REQUEST = `${namespace}/updateSocialProfile`;
export const OCCUPANT_CREATE_SOCIALPROFILE_SUCCESS = `${namespace}/createSocialProfileSuccess`;
export const SOCIALPROFILE_FETCH_REQUEST = `${namespace}/fetchOccupantSocialProfile`;
export const SOCIALPROFILE_FETCH_SUCCESS = `${namespace}/getSocialProfileSuccess`;

/**
 * General / Generic
 */
export const OCCUPANT_CLEAR_ERRORS = `${namespace}/clearErrors`;

/**
 * Occupant Skill
 */
export const OCCUPANTSKILL_CREATE_REQUEST = `${namespace}/createOccupantSkill`;
export const OCCUPANTSKILL_UPDATE_REQUEST = `${namespace}/updateOccupantSkill`;

export const OCCUPANTSKILL_CREATE_SUCCESS = `${namespace}/createOccupantSkillSuccess`;
export const OCCUPANTSKILL_UPDATE_SUCCESS = `${namespace}/updateOccupantSkillSuccess`;
export const OCCUPANTSKILL_CREATE_FAILURE = `${namespace}/createOccupantSkillFailure`;
export const OCCUPANTSKILLS_GET_REQUEST = `${namespace}/fetchOccupantSkills`;
export const OCCUPANTSKILLS_GET_SUCCESS = `${namespace}/getOccupantSkillsSuccess`;

/*
 * Occupant Income Profile
 */
export const OCCUPANTINCOMEPROFILE_CREATE_REQUEST = `${namespace}/createMonthlyIncomeProfile`;
export const OCCUPANTINCOMEPROFILE_FETCH_REQUEST = `${namespace}/fetchMonthlyIncomeProfile`;
export const OCCUPANTINCOMEPROFILE_SUCCESS = `${namespace}/getIncomeProfileSuccess`;
export const OCCUPANTINCOMEPROFILE_UPDATE_REQUEST = `${namespace}/updateMonthlyIncomeProfile`;

/*
 * Occupant Expenditure Profiles
 */
export const OCCUPANTEXPENDITURE_CREATE_REQUEST = `${namespace}/createMonthlyExpenditureProfile`;
export const OCCUPANTEXPENDITURE_FETCH_REQUEST = `${namespace}/fetchMonthlyExpenditureProfile`;
export const OCCUPANTEXPENDITURE_SUCCESS = `${namespace}/getExpenditureProfileSuccess`;
export const OCCUPANTEXPENDITURE_UPDATE_REQUEST = `${namespace}/updateMonthlyExpenditureProfile`;
