import { Customer } from '@/domain/customer';
import { Data } from '@/types';
import { success, failure, clear } from '../state/reducers';
import {
  createCustomer,
  getCustomer,
  updateCustomer,
  removeCustomer,
  searchForCustomer,
  createApplicationRequest,
  updateApplicationRequest,
  checkApplicationStatus,
  fetchApplicationRequest
} from '../state/effects';

export default {
  namespace: 'customer',
  state: {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<Customer>,
  reducers: {
    success,
    failure,
    clear
  },
  effects: {
    createCustomer,
    updateCustomer,
    getCustomer,
    removeCustomer,
    searchForCustomer,
    createApplicationRequest,
    updateApplicationRequest,
    checkApplicationStatus,
    fetchApplicationRequest 
  },
};
