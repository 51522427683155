import { WardConsilor } from '@/domain/ward-consilor';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';
import { RequestData } from '@ant-design/pro-table';

export const createWardConsilorRecord = (payload: WardConsilor) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.post<WardConsilor>(
        `${api_base}/api/ward-consilors`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error creating minor household profile ${err}`);
      reject(err);
    }
  });
};

export const fetchWardConsilorList = async (
  params: { pageSize: number | undefined; page: number | undefined },
  sort: any,
  filter: any,
): Promise<RequestData<WardConsilor>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/ward-consilors?page=${page}&size=${pageSize}&sort=createdDate`,
        { getResponse: true },
      );
      const result: RequestData<WardConsilor> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error querying ward consilor endpoint ${err}`);
      reject(err);
    }
  });
};

export const fetchWardConsilor = (wardConsilorId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest.get(
        `${api_base}/api/ward-consilors/${wardConsilorId}`,
        {
          errorHandler: (err) => {
            console.log(`an error has occured: ${err}`);
            reject(err);
          },
          getResponse: true,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ${wardConsilorId} ,  ${err}`);
      reject(err);
    }
  });
};

export const putWardConsilor = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.put<WardConsilor>(
        `${api_base}/api/ward-consilors/${payload.id}`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ,  ${err}`);
      reject(err);
    }
  });
};

export const deleteWardConsilorRequest = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.delete<WardConsilor>(
        `${api_base}/api/ward-consilors/${payload}`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ,  ${err}`);
      reject(err);
    }
  });
};
