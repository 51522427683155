import { MunicipalService } from '@/domain/municipal-service';
import { httpRequest } from '@/pages/common/interceptors';
import { api_base } from '@/pages/common/services';
import { RequestData } from '@ant-design/pro-table';

export const getListMunicipalService = (
  params: { pageSize: number | undefined; page: number | undefined },
  _sort: any,
  _filter: any,
): Promise<RequestData<MunicipalService>> => {
  return new Promise(async (resolve, reject) => {
    try {
      const { pageSize, page } = params;
      const {
        data: { data, total },
        response: { headers, ok },
      } = await httpRequest.get(
        `${api_base}/api/municipal-services?page=${page}&size=${pageSize}&sort=createdDate`,
        { getResponse: true },
      );
      const result: RequestData<MunicipalService> = {
        data,
        success: ok,
        total,
      };
      resolve(result);
    } catch (err) {
      console.log(`error fetching users ${err}`);
      reject(err);
    }
  });
};

export const getMunicipalService = (municipalServiceId: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await httpRequest.get(
        `${api_base}/api/municipal-services/${municipalServiceId}`,
        {
          getResponse: true,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ${municipalServiceId} ,  ${err}`);
      reject(err);
    }
  });
};

export const createMunicipalService = (payload: MunicipalService) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.post<MunicipalService>(
        `${api_base}/api/municipal-services`,
        {
          data: payload,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error creating municipal service ${err}`);
      reject(err);
    }
  });
};

export const updateMunicipalService = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.put<MunicipalService>(
        `${api_base}/api/municipal-services/${payload.id}`,
        {
          data: payload.data,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error updating municipal service ${err}`);
      reject(err);
    }
  });
};

export const deleteMunicipalService = (id: number) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const data = await httpRequest.delete<MunicipalService>(
        `${api_base}/api/municipal-services/${id}`,
        {
          data: id,
          headers: headers,
        },
      );
      resolve(data);
    } catch (err) {
      console.log(`error: ,  ${err}`);
      reject(err);
    }
  });
};
