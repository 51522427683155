import {
  createMinorhouseholdProfileRecord,
  fetchMinorhouseholdProfile,
  updateMinorhousehold,
} from '@/pages/minorHouseholdProfile/services/minorHouseholdProfile.service';
import { MinorHouseholdProfile } from '@/domain/minor-household-profile';
import { Action, Data, HttpTypes, ServerData } from '@/types';
import {
  MINORHOUSEHOLDPROFILE_SUCCESS,
  MINORHOUSEHOLDPROFILE_FAILURE,
} from '@/pages/minorHouseholdProfile/state/actions/actionTypes';

export function* createMinorHouseholdProfile(
  { payload }: Action<Data<MinorHouseholdProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: MinorHouseholdProfile = yield call(
      createMinorhouseholdProfileRecord,
      payload,
    );
    yield put({
      type: MINORHOUSEHOLDPROFILE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getMinorHouseholdProfileByProperty(
  { payload }: Action<Data<any>>,
  { call, put }: { call: any; put: any },
) {
  try {
    console.log('here');
    const data: MinorHouseholdProfile = yield call(
      fetchMinorhouseholdProfile,
      payload,
    );
    yield put({
      type: MINORHOUSEHOLDPROFILE_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* updateMinorhouseholdProfile(
  { payload }: Action<Data<MinorHouseholdProfile>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: MinorHouseholdProfile = yield call(
      updateMinorhousehold,
      payload,
    );
    yield put({
      type: MINORHOUSEHOLDPROFILE_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* handleNetworkErrors(err: any, put: any, call: any) {
  console.log(`err: ${JSON.stringify(err)}`);
  if (err?.data?.statusCode) {
    const httpError: HttpTypes.RequestResponse<
      Data<MinorHouseholdProfile>
    > = err;
    const {
      data: { statusCode },
    } = httpError;
    if (statusCode === 400) {
      yield put({
        type: MINORHOUSEHOLDPROFILE_FAILURE,
        payload: httpError.data,
      });
    } else if (statusCode === 403) {
      yield call({ type: 'auth/loginExpired', payload: httpError });
    } else if (statusCode === 401) {
      yield put({ type: 'auth/loginExpired', payload: httpError });
    } else {
      yield put({ type: 'createFailure', payload: httpError });
    }
  } else {
    yield put({ type: 'createFailure', payload: err });
  }
}
