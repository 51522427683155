import { Application, IncomeBelowAmountDropdown } from '@/domain/application';
import { Action, Data, HttpTypes, ReportsData } from '@/types';
import { APPLICATION_FAILURE } from '@/pages/application/state/actions/actionTypes';
import {
  APPLICATION_REPORT_BY_USER_SUCCESS,
  APPLICATION_USERS_SUCCESS,
  APPLICATION_GET_REPORT_FAILURE,
  APPLICATION_GET_REPORT_SUCCESS,
  APPLICATION_REPORT_BY_WARDSTATUS_FAILURE,
  APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_FAILURE,
  APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_SUCCESS,
  APPLICATION_REPORT_BY_WARDSTATUS_SUCCESS,
  APPLICATION_STATUS_GET_BY_FINANCIAL_YEAR_REPORT_SUCCESS,
  FETCH_REPORTS_CUSTOMERS_FAIL,
  FETCH_REPORTS_CUSTOMERS_SUCCESS,
  APPLICATION_STATUS_GET_APPLICATION_AGE_BY_STATUS_SUCCESS,
  FETCH_REPORTS_INDIGENT_BELOW_AMOUNT_SUCCESS,
  FETCH_REPORTS_INDIGENT_BELOW_AMOUNT_FAIL,
} from '@/pages/reports/state/actions/actionTypes';
import {
  fetchUsers,
  getApplicationByUserFullReport,
  getApplicationByWardByFinancialYearRequest,
  getApplicationByWardReportRequest,
  getApplicationByWardRegionReportFinancialYearReportRequest,
  getApplicationByWardRegionReportRequest,
  getApplicationByStatusbyFinancialYearFullReport,
  getApplicationsByReferenceReportRequest,
  fetchCustomers,
  getApplicationAgeByStatusReportRequest,
  fetchIndigentBelowAmountDropdown,
} from '@/pages/reports/services/report.service';
import { handleServerErrors } from '@/pages/common/state/effects';

export function* getApplicationByWardRegionReport(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(getApplicationByWardRegionReportRequest, payload);
    yield put({
      type: APPLICATION_REPORT_BY_WARDSTATUS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(
      err,
      put,
      call,
      APPLICATION_REPORT_BY_WARDSTATUS_FAILURE,
    );
  }
}

export function* getApplicationByWardRegionReportFinancialYearReport(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(
      getApplicationByWardRegionReportFinancialYearReportRequest,
      payload,
    );
    yield put({
      type: APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(
      err,
      put,
      call,
      APPLICATION_REPORT_BY_WARDSTATUS_FINANCIAL_YEAR_FAILURE,
    );
  }
}

export function* getApplicationByWardReport(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
      getApplicationByWardReportRequest,
      payload,
    );
    yield put({ type: APPLICATION_GET_REPORT_SUCCESS, payload: data });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(
      err,
      put,
      call,
      APPLICATION_GET_REPORT_FAILURE,
    );
  }
}

export function* getApplicationByWardByFinancialYear(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<Data<Application>> = yield call(
      getApplicationByWardByFinancialYearRequest,
      payload,
    );
    yield put({ type: APPLICATION_GET_REPORT_SUCCESS, payload: data });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(
      err,
      put,
      call,
      APPLICATION_GET_REPORT_FAILURE,
    );
  }
}

export function* getAllUsers(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(fetchUsers);
    yield put({
      type: APPLICATION_USERS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(err, put, call, APPLICATION_FAILURE);
  }
}

export function* getApplicationByUserFullReportRequest(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(getApplicationByUserFullReport, payload);
    yield put({
      type: APPLICATION_REPORT_BY_USER_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(err, put, call, APPLICATION_FAILURE);
  }
}

export function* getApplicationByStatusbyFinancialYearFullReportRequest(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(getApplicationByStatusbyFinancialYearFullReport, payload);
    yield put({
      type: APPLICATION_STATUS_GET_BY_FINANCIAL_YEAR_REPORT_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(err, put, call, APPLICATION_FAILURE);
  }
}

export function* getApplicationsByReferenceReport(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(getApplicationsByReferenceReportRequest, payload);
    yield put({
      type: APPLICATION_STATUS_GET_BY_FINANCIAL_YEAR_REPORT_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(err, put, call, APPLICATION_FAILURE);
  }
}
export function* getAllCustomers(
  { payload }: Action<Data<any>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<any> = yield call(
      fetchCustomers,
      payload,
    );
    yield put({
      type: FETCH_REPORTS_CUSTOMERS_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleServerErrors<Data<any>>(
      err,
      put,
      call,
      FETCH_REPORTS_CUSTOMERS_FAIL,
    );
  }
}

export function* getApplicationAgeByStatusReport(
  { payload }: Action<any>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: HttpTypes.RequestResponse<
      ReportsData<any, any, any, any, any, any>
    > = yield call(getApplicationAgeByStatusReportRequest, payload);
    yield put({
      type: APPLICATION_STATUS_GET_APPLICATION_AGE_BY_STATUS_SUCCESS,
      payload: data,
    });
    return data;
  } catch (err) {
    yield handleServerErrors<Data<any>>(err, put, call, APPLICATION_FAILURE);
  }
}
export function* getIndigentBelowAmountDropdown(
  { payload }: Action<Data<any>>,  { call, put }: { call: any; put: any },
  ) {
try {
  const data: HttpTypes.RequestResponse<  IncomeBelowAmountDropdown[]
    > = yield call(fetchIndigentBelowAmountDropdown, payload);
    yield put({
      type: FETCH_REPORTS_INDIGENT_BELOW_AMOUNT_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleServerErrors<Data<any>>(
      err,
      put,
      call,
      FETCH_REPORTS_INDIGENT_BELOW_AMOUNT_FAIL,
    );
  }
}
