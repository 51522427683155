import { CreateCustomerAssetProfile } from '@/pages/assetProfile/services/assetProfile.service';
import { CustomerAssetProfile } from '@/domain/customer-asset-profile';
import { Action, Data, HttpTypes } from '@/types';
import { create, getByCustomer, updateCustomerProfile } from '../state/effects';
import {
  createSuccess,
  createFailure,
  updateCustomer,
  clear,
} from '../state/reducers';

export default {
  namespace: 'assetProfile',
  state: {
    Record: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<CustomerAssetProfile>,
  reducers: {
    createSuccess,
    createFailure,
    updateCustomer,
    clear,
  },
  effects: {
    create,
    getByCustomer,
    updateCustomerProfile,
  },
};
