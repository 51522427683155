import { AnalyticsData, Data } from '@/types';
import { success, failure, clear } from '@/pages/application/state/reducers';
import {
  getTotalApplicationSuccess,
  getTotalApprovalRateSuccess,
  getTotalHouseholdSuccess,
} from '@/pages/analytics//state/reducers';
import {
  getTotalApplications,
  getTotalApprovalRate,
  getTotalHousehold,
} from '@/pages/analytics//state/effects';

export default {
  namespace: 'analytics',
  state: {
    Record: {},
    ServerData: { Errors: {} },
  } as Data<AnalyticsData<any, any, any, any>>,
  reducers: {
    success,
    failure,
    clear,
    getTotalApplicationSuccess,
    getTotalApprovalRateSuccess,
    getTotalHouseholdSuccess,
  },
  effects: {
    getTotalApplications,
    getTotalHousehold,
    getTotalApprovalRate,
  },
};
