import {
  getStandTypes,
  getWardConsilors,
  create,
  getProperty,
  update,
  updateGrantHouseholdProfile,
  getGranthouseholdProfile,
  getMunicipalServices,
  getByCustomerProperty,
} from '@/pages/property/state/effects';
import { Property } from '@/domain/property';
import { AdditionalDataFourth } from '@/types';
import { StandType } from '@/domain/stand-type';
import { WardConsilor } from '@/domain/ward-consilor';
import { MunicipalService } from '@/domain/municipal-service';
import {
  clearErrors,
  createError,
  createFailure,
  createSuccess,
  getStandTypesSuccess,
  getWardConsilorsSuccess,
  getPropertySuccess,
  updateGrantHouseholdProfileSucccess,
  reset,
  getMunicipalServicesSuccess,
} from '@/pages/property/state/reducers';
import { GrantsHouseholdProfile } from '@/domain/grants-household-profile';

export default {
  namespace: 'property',
  state: {
    Record: {},
    PrimaryStash: {},
    SecondaryStash: {},
    ThirdStash: {},
    FormStatus: {},
    ServerData: { Errors: {} },
  } as AdditionalDataFourth<
    Property,
    WardConsilor[],
    StandType[],
    MunicipalService[],
    GrantsHouseholdProfile
  >,

  reducers: {
    createSuccess,
    createFailure,
    createError,
    getWardConsilorsSuccess,
    getStandTypesSuccess,
    getPropertySuccess,
    clearErrors,
    updateGrantHouseholdProfileSucccess,
    reset,
    getMunicipalServicesSuccess,
  },
  effects: {
    create,
    getWardConsilors,
    getStandTypes,
    getProperty,
    update,
    updateGrantHouseholdProfile,
    getGranthouseholdProfile,
    getMunicipalServices,
    getByCustomerProperty,
  },
};
