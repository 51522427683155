import { handleNetworkErrors } from '@/pages/customer/state/effects';
import { Action, Data } from '@/types';
import {
  getTotalApplicationsRequest,
  getTotalApprovalRateRequest,
  getTotalHouseholdRequest,
} from '../../services/analytics.service';
import {
  ANALYTICS_GET_TOTAL_APPLICATIONS_SUCCESS,
  ANALYTICS_GET_TOTAL_APPROVAL_RATE_SUCCESS,
  ANALYTICS_GET_TOTAL_HOUSEHOLD_SUCCESS,
} from '@/pages/analytics/state/actions/actionTypes';

export type TotalApplications = {
  currentCount: string;
  percentageChange: string;
  isNegative: boolean;
};

export type TotalApproval = {
  currentCount: string;
  percentageChange: string;
  isNegative: boolean;
};

export type TotalHousehold = {
  currentCount: string;
  percentageChange: string;
  isNegative: boolean;
};
export function* getTotalApplications(
  { payload }: Action<Data<TotalApplications>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: any = yield call(getTotalApplicationsRequest, payload);
    yield put({
      type: ANALYTICS_GET_TOTAL_APPLICATIONS_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getTotalApprovalRate(
  { payload }: Action<Data<TotalApproval>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: any = yield call(getTotalApprovalRateRequest, payload);
    yield put({
      type: ANALYTICS_GET_TOTAL_APPROVAL_RATE_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}

export function* getTotalHousehold(
  { payload }: Action<Data<TotalHousehold>>,
  { call, put }: { call: any; put: any },
) {
  try {
    const data: any = yield call(getTotalHouseholdRequest, payload);
    yield put({
      type: ANALYTICS_GET_TOTAL_HOUSEHOLD_SUCCESS,
      payload: data,
    });
  } catch (err: any) {
    yield handleNetworkErrors(err, put, call);
  }
}
