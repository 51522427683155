import { Action, Data, FormStatus, HttpTypes, ServerData } from '@/types';
import { Occupant } from '@/domain/occupant';
import { OccupantSkill } from '@/domain/occupant-skill';
import { SocialProfile } from '@/domain/social-profile';
import { MonthlyIncomeProfile } from '@/domain/monthly-income-profile';
import { MonthlyExpenditureProfile } from '@/domain/monthly-expenditure-profile';

export const createSuccess = (
  state: Data<Occupant>,
  action: Action<Data<Occupant>>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const updateSuccess = (
  state: Data<Occupant>,
  action: Action<Data<Occupant>>,
) => {
  const { payload } = action;
  const { Record } = state;
  // Record.socialProfile
  Object.assign(Record, { ...payload });
  return { ...state, Record };
};

export const createFailure = (
  state: Data<Occupant>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  return { ...state, ServerData };
};

export const createError = (
  state: Data<Occupant>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  if (ServerData) {
    Object.assign(ServerData.Errors, payload.error);
  }

  return { ...state, ServerData };
};

export const clearErrors = (
  state: Data<Occupant>,
  action: Action<Data<Occupant>>,
) => {
  const { ServerData } = state;
  if (ServerData) {
    Object.assign(ServerData.Errors, {});
  }
  return { ...state, ServerData };
};

export const reset = (state: Data<Occupant>) => {
  const data = {
    Record: {
      socialProfile: {
        occupantSkills: [] as Array<OccupantSkill>,
      } as SocialProfile,
      monthlyExpenditureProfile: {} as MonthlyExpenditureProfile,
      monthlyIncomeProfile: {} as MonthlyIncomeProfile,
    } as Occupant,
    FormStatus: { step: 0 } as FormStatus,
    ServerData: { Errors: {} } as ServerData,
  } as Data<Occupant>;
  return { ...data };
};

export const getOccupantSuccess = (
  state: Data<Occupant>,
  action: Action<Occupant>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const getOccupantFailure = (
  state: Data<Occupant>,
  action: Action<ServerData>,
) => {
  const { payload } = action;
  const { Record, ServerData } = state;
  Object.assign(Record, {});
  if (ServerData) {
    Object.assign(ServerData);
  }

  return { ...state, Record, ServerData };
};

export const getSocialProfileSuccess = (
  state: Data<Occupant>,
  action: Action<SocialProfile>,
) => {
  const { payload } = action;
  const { Record } = state;

  const { socialProfile } = Record as Occupant;
  Object.assign(socialProfile, payload);
  return { ...state, Record };
};

export const createOccupantSkillSuccess = (
  state: Data<Occupant>,
  action: Action<OccupantSkill>,
) => {
  const { payload } = action;
  const { Record } = state;
  const { socialProfile } = Record as Occupant;
  const occupantSkills =
    socialProfile?.occupantSkills || new Array<OccupantSkill>();
  occupantSkills.push(payload);
  socialProfile.occupantSkills = occupantSkills;
  return { ...state, Record };
};

export const updateOccupantSkillSuccess = (
  state: Data<Occupant>,
  action: Action<OccupantSkill>,
) => {
  const { payload } = action;
  const { Record } = state;
  const { socialProfile } = Record as Occupant;
  const occupantSkills =
    socialProfile?.occupantSkills || new Array<OccupantSkill>();

  const objIndex = occupantSkills.findIndex((obj) => obj.id == payload.id);
  occupantSkills[objIndex] = payload;
  socialProfile.occupantSkills = occupantSkills;
  return { ...state, Record };
};

export const createSocialProfileSuccess = (
  state: Data<Occupant>,
  action: Action<SocialProfile>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record?.socialProfile, { ...payload });
  console.log(Record);
  return { ...state, Record };
};

export const getOccupantSkillsSuccess = (
  state: Data<Occupant>,
  action: Action<OccupantSkill[]>,
) => {
  const { payload } = action;
  const { Record } = state;
  const occupantSkills = Array<OccupantSkill>(...payload);
  if (Record) {
    Record.socialProfile.occupantSkills = occupantSkills;
  }
  return { ...state, Record };
};

export const getIncomeProfileSuccess = (
  state: Data<Occupant>,
  action: Action<MonthlyIncomeProfile>,
) => {
  const { payload } = action;
  const { Record } = state;
  if (Record) {
    Record.monthlyIncomeProfile = payload;
  }

  return { ...state, Record };
};

export const getExpenditureProfileSuccess = (
  state: Data<Occupant>,
  action: Action<MonthlyExpenditureProfile>,
) => {
  const { payload } = action;
  const { Record } = state;
  if (Record) {
    Record.monthlyExpenditureProfile = payload;
  }

  return { ...state, Record };
};

export const deleteOccupantSuccess = (
  state: Data<Occupant>,
  action: Action<Occupant>,
) => {
  const { payload } = action;
  const { Record } = state;
  Object.assign(Record, payload);
  return { ...state, Record };
};

export const deleteOccupantFailure = (
  state: Data<Occupant>,
  action: Action<HttpTypes.ErrorResponse>,
) => {
  const { payload } = action;
  const { ServerData } = state;
  Object.assign(ServerData, payload);
  state.Record = {} as Occupant;
  return { ...state, ServerData };
};
