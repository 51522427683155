import {
  create,
  createSocialProfile,
  fetchOccupant,
  createOccupantSkill,
  fetchOccupantSkills,
  fetchOccupantSocialProfile,
  createMonthlyIncomeProfile,
  createMonthlyExpenditureProfile,
  fetchMonthlyIncomeProfile,
  fetchMonthlyExpenditureProfile,
  updateMonthlyIncomeProfile,
  updateMonthlyExpenditureProfile,
  update,
  updateSocialProfile,
  updateOccupantSkill,
  deleteOccupant,
  updateOccupantGrant,
} from '@/pages/occupant/state/effects';
import { Occupant } from '@/domain/occupant';
import {
  createOccupantSkillSuccess,
  clearErrors,
  createError,
  createFailure,
  createSuccess,
  reset,
  getOccupantSuccess,
  getOccupantFailure,
  getSocialProfileSuccess,
  getOccupantSkillsSuccess,
  getIncomeProfileSuccess,
  getExpenditureProfileSuccess,
  createSocialProfileSuccess,
  updateSuccess,
  updateOccupantSkillSuccess,
  deleteOccupantSuccess,
  deleteOccupantFailure,
} from '@/pages/occupant/state/reducers';
import { Data } from '@/types';
import { MonthlyIncomeProfile } from '@/domain/monthly-income-profile';
import { MonthlyExpenditureProfile } from '@/domain/monthly-expenditure-profile';
import { OccupantSkill } from '@/domain/occupant-skill';

export default {
  namespace: 'occupant',
  state: {
    Record: {
      socialProfile: {
        occupantSkills: Array<OccupantSkill>(),
      },
      monthlyIncomeProfile: {} as MonthlyIncomeProfile,
      monthlyExpenditureProfile: {} as MonthlyExpenditureProfile,
    },
    FormStatus: {},
    ServerData: { Errors: {} },
  } as Data<Occupant>,

  reducers: {
    createSuccess,
    createFailure,
    createError,
    clearErrors,
    createOccupantSkillSuccess,
    reset,
    getOccupantSuccess,
    getOccupantFailure,
    getSocialProfileSuccess,
    getOccupantSkillsSuccess,
    updateSuccess,
    getIncomeProfileSuccess,
    getExpenditureProfileSuccess,
    createSocialProfileSuccess,
    updateOccupantSkillSuccess,
    deleteOccupantSuccess,
    deleteOccupantFailure,
  },
  effects: {
    create,
    update,
    createSocialProfile,
    updateSocialProfile,
    getOccupant: fetchOccupant,
    createOccupantSkill,
    fetchOccupantSkills,
    fetchOccupantSocialProfile,
    createMonthlyIncomeProfile,
    createMonthlyExpenditureProfile,
    fetchMonthlyIncomeProfile,
    fetchMonthlyExpenditureProfile,
    updateMonthlyIncomeProfile,
    updateMonthlyExpenditureProfile,
    updateOccupantSkill,
    deleteOccupant,
    updateOccupantGrant,
  },
};
